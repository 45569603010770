/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import useTimeout from '@common/application/hooks/useTimeout/src';
import { css, keyframes } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { StyledNotification, StyledNotificationArea } from './styled';

enum NOTIFICATION {
  INFO,
  ALERT,
  ERROR,
  SUCCESS,
}

interface AddNotification {
  message: string | React.ReactNode;
  variant?: NOTIFICATION;
  indefinite?: boolean;
}

interface Notification {
  indefinite?: boolean;
  id: number;
  message: string | React.ReactNode;
  variant: NOTIFICATION;
}

// noinspection JSUnusedGlobalSymbols
const defaultApi = {
  notifications: [] as Notification[],
  notify: (notification: AddNotification) => {
    /*default*/
  },
  clearNotification: (id: number) => {
    /*default*/
  },
};

type NotificationsContextValue = typeof defaultApi;

const progress = keyframes`
  from {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

/**
 * Create Context
 */
const NotificationsContext = React.createContext<NotificationsContextValue>(defaultApi);

function Notification({ variant, id, message, clearNotification, indefinite }: Notification & { clearNotification: (id: number) => void }) {
  const timeoutTime = 3000;
  useTimeout(() => clearNotification(id), !indefinite ? timeoutTime : null);

  return (
    <StyledNotification variant={variant} key={id} onClick={() => clearNotification(id)}>
      <span
        css={css`
          width: 100%;
          display: flex;
          justify-content: flex-end;
          //padding-bottom: 8px;
        `}
      >
        <FontAwesomeIcon
          css={css`
            //margin-left: 12px;
            padding: 8px;
            cursor: pointer;
          `}
          icon={['fas', 'multiply']}
        />
      </span>
      <div>
        {message}
        {!indefinite && (
          <div
            css={css`
              width: 100%;
              height: 4px;
              background: ${cssVars.grey};
              margin-top: 8px;
            `}
          >
            <div
              css={css`
                height: 100%;
                background: ${cssVars.lightGrey};
                animation: ${progress} ${timeoutTime / 1000}s linear;
              `}
            ></div>
          </div>
        )}
      </div>
    </StyledNotification>
  );
}

/**
 * Custom Notifications Provider
 */
function NotificationsProvider({ children }: any) {
  // Notifications queue is managed in local useState
  const [notifications, setNotifications] = React.useState<Notification[]>(defaultApi.notifications);

  // Method to push a new notification
  const notify = React.useCallback(
    (notification: AddNotification) => {
      if (notifications.map((n) => n.message).includes(notification.message)) return;
      setNotifications((notifications) => [
        ...notifications,
        {
          id: new Date().getTime(), // we use UIDs in the final ver
          variant: NOTIFICATION.ERROR,
          ...notification,
        } as Notification,
      ]);
    },
    [setNotifications],
  );

  // Method to clear a notification
  const clearNotification = React.useCallback(
    (id: number) => {
      const nextNotifications = notifications.filter((n) => n.id !== id);
      setNotifications(nextNotifications);
    },
    [notifications, setNotifications],
  );

  // Return Provider with full API
  const api = { notifications, notify, clearNotification };
  return (
    <NotificationsContext.Provider value={api}>
      {children}
      <StyledNotificationArea>
        {notifications.map((notification) => (
          <Notification key={notification.id} {...notification} clearNotification={clearNotification} />
        ))}
      </StyledNotificationArea>
    </NotificationsContext.Provider>
  );
}

// Convenience import hook
function useNotifications() {
  return React.useContext(NotificationsContext);
}

export { useNotifications, NOTIFICATION, NotificationsProvider, NotificationsContext };
