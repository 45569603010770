/** @format */

import axios from 'axios';

export class Contact {
  id: number;
  siteId: number;
  name: string;
  role: string;
  phone: string;
  email: string;
  createdAt: string;
  approvedAt: string | null | Date;
  proposedBy: string;
  updatedAt: string;
  deletedAt: string;

  constructor(siteId: number, name: string, role: string, phone: string, email: string, approved?: boolean, proposedBy?: string) {
    this.siteId = siteId;
    this.name = name;
    this.role = role;
    this.phone = phone;
    this.email = email;
    this.approvedAt = null; // TODO: this login needs to be on the server as it is a security flaw: approved ? new Date() : null;
    this.proposedBy = proposedBy;
  }

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Contact; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/contacts`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts ?? { deleted: false }),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static get(id: number): Promise<{ message: string; paginated: boolean; payload: Contact }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/contacts/${id}`).then((res) => ({ ...res.data }));
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/contacts/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/contacts/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static approve(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/contacts/${id}/approve`).then((res) => ({ ...res.data }));
  }

  static update(
    updatedContact: {
      siteId: number;
      name: string;
      role: string;
      phone: string;
      email: string;
    },
    id: number,
  ): Promise<Record<string, unknown>> {
    return axios.patch(`${window.location.protocol}//${window.location.host}/api/v1/contacts/${id}`, { payload: updatedContact }).then((res) => ({ ...res.data }));
  }

  static getAddTemplate(locale: string): Promise<Record<string, unknown>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/templates/contacts/add`, {
      // responseType: 'arraybuffer',
      params: {
        locale: locale,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postAddTemplate(file: File): Promise<Record<string, unknown>> {
    return axios.post(
      `${window.location.protocol}//${window.location.host}/api/v1/templates/contacts/add`,
      { payload: file },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  add(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/contacts`, {
        payload: this,
      })
      .then((res) => ({ ...res.data }));
  }

  suggest(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/contacts/suggest`, {
        payload: this,
      })
      .then((res) => ({ ...res.data }));
  }
}
