/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import process from 'process';
import React from 'react';
import styled from '@emotion/styled';

interface EnvironmentLabelProps {}

const StyledEnvironmentLabel = styled.div`
  //font-size: 18px;
  background: ${cssVars.grey};
  color: ${() => {
    switch (process.env.NODE_ENV) {
      case 'development':
        return cssVars.green;
      case 'production':
        return cssVars.blue;
      default:
        return cssVars.orange;
    }
  }};
  display: grid;
  place-items: center;
  font-weight: bold;
  z-index: 99;
  border: 1px solid ${cssVars.grey};
  padding: 2px;
  border-radius: 3px;
  font-size: 8px;
`;

const env = process.env.NEXT_PUBLIC_TRACKUP_ENV;

function EnvironmentLabel(props: EnvironmentLabelProps) {
  return <StyledEnvironmentLabel>{env?.toUpperCase()}</StyledEnvironmentLabel>;
}

export default EnvironmentLabel;
