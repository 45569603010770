/** @format */

function convertFileToB64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File)) {
      reject(new Error('Invalid input. Expected a File object.'));
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = function (event) {
      // @ts-ignore
      const base64String = event.target.result.split(',')[1]; // Get base64 part after the comma
      resolve(base64String);
    };

    fileReader.onerror = function () {
      reject(new Error('Error occurred while reading the file.'));
    };

    fileReader.readAsDataURL(file);
  });
}

export default convertFileToB64;
