/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import useNotificationActions from '@common/application/hooks/useNotificationActions/useNotificationActions';
import useStrings from '@common/application/hooks/useStrings/useStrings';
import { Notification } from '@common/model/apiClient/Notification';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface NotificationProps {
  notification: Notification;
}

const StyledNotification = styled.div<{ isRead: boolean }>`
  height: 50px;
  display: flex;
  //cursor: pointer;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 4px;
  //padding-inline: 4px;
  font-size: 0.9rem;
  position: relative;
  background: ${(props) => (!props.isRead ? cssVars.blue + '15' : 'transparent')};

  &:hover {
    background: ${cssVars.blue}22;
  }
  &:before {
    content: '';
    position: absolute;
    left: 4%;
    bottom: 0;
    height: 1px;
    width: 92%;
    border-bottom: 1px solid ${cssVars.lightGrey};
  }
  :not(:last-of-type) {
  }
`;

const NotificationBox = styled.div<{ isRead: boolean; hasClickEvent: boolean }>`
  padding: 16px;
  cursor: ${(props) => (props.hasClickEvent ? 'pointer' : 'default')};
  > div:first-of-type {
    color: ${(props) => (!props.isRead ? cssVars.black : cssVars.silverGrey)};
    //font-weight: ${(props) => (props.isRead ? 'regular' : 'bold')};
    // font-size: ${(props) => (!props.isRead ? '1rem' : '0.9rem')};
  }
  > div:last-of-type {
    color: ${cssVars.silverGrey};
    font-size: 0.8rem;
  }
`;

const DeleteBox = styled.span`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-inline-end: 16px;
  svg {
    color: ${cssVars.red}99;
    &:hover {
      transform: scale(1.25);
      color: ${cssVars.red};
      transition: 0.2s all ease;
    }
  }
`;

function NotificationCard({ notification }: NotificationProps) {
  const { notificationStrings } = useStrings();
  const { locale } = useRouter();
  const clickEvent = useNotificationActions(notification);
  const router = useRouter();

  const queryClient = useQueryClient();

  const deleteNotification = useMutation(['delete_notification'], (notificationId: number) => Notification.delete(notificationId), {
    onSuccess: () => queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('user_notification_query') }),
  });

  const markRead = useMutation(['mark_all_notifications_read'], (notificationId: number) => Notification.markRead(notificationId), {
    onSuccess: () => queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('user_notification_query') }),
  });

  return (
    <StyledNotification isRead={notification.read}>
      <NotificationBox
        hasClickEvent={!!clickEvent}
        isRead={notification.read}
        onClick={() => {
          if (clickEvent) {
            clickEvent();
          }
          markRead.mutate(notification.id);
        }}
      >
        <div>{notificationStrings[notification.table]?.[notification.event]}</div>
        <div>{new Date(notification.createdAt).toLocaleString(locale, { dateStyle: 'medium', timeStyle: 'short' })}</div>
      </NotificationBox>
      <DeleteBox onClick={() => deleteNotification.mutate(notification.id)}>
        <FontAwesomeIcon icon={['far', 'trash-xmark']} />
      </DeleteBox>
    </StyledNotification>
  );
}

export default NotificationCard;
