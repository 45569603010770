/** @format */

import { ReactNode } from 'react';
import { StyledTagInputWrapper } from './styled';

interface TagInputWrapperProps {
  focussed: boolean;
  children: ReactNode;

  onClick?(): void;
}

function TagInputWrapper(props: TagInputWrapperProps) {
  return (
    <StyledTagInputWrapper onClick={props.onClick} focussed={props.focussed}>
      {props.children}
    </StyledTagInputWrapper>
  );
}

export default TagInputWrapper;
