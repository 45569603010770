/** @format */

const genLink = (font) => {
  if (typeof window === 'undefined') {
    return null;
  }
  return `url('${location.protocol}//${location.host}/${font}.woff2') format('woff2'), url('${location.protocol}//${location.host}/${font}.ttf') format('truetype');`;
};

// language=CSS
export const fonts = `

  /**
   * PROXIMA NOVA
   */

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-Black')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-BlackItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-ExtraBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-ExtraBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-Thin')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-ThinItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-SemiBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-RegularItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-LightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-Medium')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 500;
  }

  @font-face {
    font-family  : 'Proxima Nova';
    src          : ${genLink('fonts/ProximaNova/ProximaNova-MediumItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 500;
  }

  /**
   * ROBOTO
   */

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-Black')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-BlackItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-ExtraBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-ExtraBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-Thin')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-ThinItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-SemiBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-LightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-Medium')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 500;
  }

  @font-face {
    font-family  : 'Roboto';
    src          : ${genLink('fonts/Roboto/Roboto-MediumItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 500;
  }

  /**
   * ROBOTO SLAB
   */

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-Black')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-ExtraBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-Thin')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Roboto Slab';
    src          : ${genLink('fonts/RobotoSlab/RobotoSlab-Medium')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 500;
  }

  /**
   * RALEWAY
   */

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-Black')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-BlackItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-ExtraBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-ExtraBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-Thin')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-ThinItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-SemiBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-LightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-Medium')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 500;
  }

  @font-face {
    font-family  : 'Raleway';
    src          : ${genLink('fonts/Raleway/Raleway-MediumItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 500;
  }

  /**
   * PLAYFAIR DISPLAY
   */

  @font-face {
    font-family  : 'Playfair Display';
    src          : ${genLink('fonts/PlayfairDisplay/PlayfairDisplay-Black')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Playfair Display';
    src          : ${genLink('fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Playfair Display';
    src          : ${genLink('fonts/PlayfairDisplay/PlayfairDisplay-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Playfair Display';
    src          : ${genLink('fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Playfair Display';
    src          : ${genLink('fonts/PlayfairDisplay/PlayfairDisplay-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Playfair Display';
    src          : ${genLink('fonts/PlayfairDisplay/PlayfairDisplay-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  /**
   * OSWALD
   */

  @font-face {
    font-family  : 'Oswald';
    src          : ${genLink('fonts/Oswald/Oswald-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Oswald';
    src          : ${genLink('fonts/Oswald/Oswald-ExtraLight')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 200;
  }

  @font-face {
    font-family  : 'Oswald';
    src          : ${genLink('fonts/Oswald/Oswald-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Oswald';
    src          : ${genLink('fonts/Oswald/Oswald-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Oswald';
    src          : ${genLink('fonts/Oswald/Oswald-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Oswald';
    src          : ${genLink('fonts/Oswald/Oswald-Medium')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 500;
  }

  /**
   * OPEN SANS
   */

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-ExtraBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-ExtraBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-SemiBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Open Sans';
    src          : ${genLink('fonts/OpenSans/OpenSans-LightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 300;
  }

  /**
   * MERRIWEATHER
   */

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-Black')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-BlackItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Merriweather';
    src          : ${genLink('fonts/Merriweather/Merriweather-LightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 300;
  }

  /**
   * KULIM PARK
   */

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-ExtraLight')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 200;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-ExtraLightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 200;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-SemiBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Kulim Park';
    src          : ${genLink('fonts/KulimPark/KulimPark-LightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 300;
  }

  /**
   * INRIA SERIF
   */

  @font-face {
    font-family  : 'Inria Serif';
    src          : ${genLink('fonts/InriaSerif/InriaSerif-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Inria Serif';
    src          : ${genLink('fonts/InriaSerif/InriaSerif-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Inria Serif';
    src          : ${genLink('fonts/InriaSerif/InriaSerif-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Inria Serif';
    src          : ${genLink('fonts/InriaSerif/InriaSerif-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Inria Serif';
    src          : ${genLink('fonts/InriaSerif/InriaSerif-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Inria Serif';
    src          : ${genLink('fonts/InriaSerif/InriaSerif-LightItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 300;
  }

  /**
   * HEEBO
   */

  @font-face {
    font-family  : 'Heebo';
    src          : ${genLink('fonts/Heebo/Heebo-Black')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 900;
  }

  @font-face {
    font-family  : 'Heebo';
    src          : ${genLink('fonts/Heebo/Heebo-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Heebo';
    src          : ${genLink('fonts/Heebo/Heebo-ExtraBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 800;
  }

  @font-face {
    font-family  : 'Heebo';
    src          : ${genLink('fonts/Heebo/Heebo-Thin')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 100;
  }

  @font-face {
    font-family  : 'Heebo';
    src          : ${genLink('fonts/Heebo/Heebo-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Heebo';
    src          : ${genLink('fonts/Heebo/Heebo-Light')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 300;
  }

  @font-face {
    font-family  : 'Heebo';
    src          : ${genLink('fonts/Heebo/Heebo-Medium')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 500;
  }

  /**
   * GELASIO
   */

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-Bold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-BoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 700;
  }

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-SemiBold')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-SemiBoldItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 600;
  }

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-Medium')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 500;
  }

  @font-face {
    font-family  : 'Gelasio';
    src          : ${genLink('fonts/Gelasio/Gelasio-MediumItalic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 500;
  }

  /**
   * BASKERVVILLE
   */

  @font-face {
    font-family  : 'Baskervville';
    src          : ${genLink('fonts/Baskervville/Baskervville-Regular')};
    font-display : auto;
    font-style   : normal;
    font-weight  : 400;
  }

  @font-face {
    font-family  : 'Baskervville';
    src          : ${genLink('fonts/Baskervville/Baskervville-Italic')};
    font-display : auto;
    font-style   : italic;
    font-weight  : 400;
  }
`;
