/** @format */

import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css, keyframes } from '@emotion/react';

interface LoadingSpinnerProps {
  height?: string;
  width?: string;
}

const spin = keyframes`
0%{
  transform: rotate(0deg);
}
  
  100%{
    transform: rotate(360deg);
  }
`;

const StyledLoadingSpinner = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  > svg {
    animation: ${spin} 2s linear infinite;
  }
`;

function LoadingSpinner(props: LoadingSpinnerProps) {
  return (
    <StyledLoadingSpinner
      {...props}
      css={css`
        svg {
          ${props.height ? `height: ${props.height};` : ''} ${props.width ? `width: ${props.width};` : ''}
        }
      `}
    >
      <FontAwesomeIcon icon={['fas', 'spinner']} color={'#40a9ff'} />
    </StyledLoadingSpinner>
  );
}

export default LoadingSpinner;
