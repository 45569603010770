/** @format */

import { fonts } from '@common/application/styles/fonts';
import { css, Global } from '@emotion/react';
import React from 'react';

// noinspection CssUnknownTarget
const styles = css`
  ${fonts}

  body {
    margin: 0;
    padding: 0;
    border: none;
    font-family: 'Proxima Nova', Roboto, sans-serif;
  }

  h1 {
    font-size: 2em;
  }

  #loading-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const cssVars = {
  blue: `#40a9ff`,
  lightBlue: `#e6f7ff`,
  lightGrey: '#d9d9d9',
  darkGrey: '#2e2f2f',
  silverGrey: '#a6a6a6',
  grey: '#555555',
  red: '#B3001B',
  // red: '#ff4040',
  black: '#000000',
  yellow: '#ffb940',
  white: '#ffffff',
  offWhite: '#fafafa',
  green: '#B3D89C',
  orange: '#FC7A1E',
  charcoal: '#2e5266',
};

export const GlobalStyles = () => <Global styles={styles} />;
