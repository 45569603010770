/** @format */

import ListBoxSection from '@atoms/ListBoxSection';
import Option from '@atoms/Select/Option';
import Scrollbar from '@atoms/Scrollbar';
import styled from '@emotion/styled';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import * as React from 'react';
import { useListBox } from 'react-aria';
import type { ListState } from 'react-stately';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

const List = styled.ul`
  max-height: 300px;
  //overflow: auto;
  list-style: none;
  padding: 0;
  margin: 4px 0;
  outline: none;
  width: 100%;
`;

export function ListBox(props: ListBoxProps) {
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <Scrollbar>
      <List {...listBoxProps} ref={listBoxRef}>
        {[...state.collection].map((item) =>
          item.type === 'section' ? <ListBoxSection key={item.key} section={item} state={state} /> : <Option key={item.key} item={item} state={state} />,
        )}
      </List>
    </Scrollbar>
  );
}

export default ListBox;
