/** @format */

import axios from 'axios';

export interface IValue {
  hidden: boolean;
  mandatory: boolean;
}

export class FieldConfiguration {
  id: number;
  companyId: number;
  value: Record<string, IValue>;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;

  constructor(companyId: number, value: Record<string, IValue>) {
    this.companyId = companyId;
    this.value = value;
  }

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: FieldConfiguration; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/fieldconfigurations`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static addAllMissingCompanies(): Promise<{ message: string; paginated: boolean; payload: Array<{ id: number }> }> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/fieldconfigurations/addmissingcompanies`).then((res) => ({ ...res.data }));
  }

  static get(id: number): Promise<{ message: string; paginated: boolean; payload: FieldConfiguration }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/fieldconfigurations/${id}`).then((res) => ({ ...res.data }));
  }

  static getByCompany(id: number): Promise<{ message: string; paginated: boolean; payload: FieldConfiguration }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/company/${id}/fieldconfiguration`).then((res) => ({ ...res.data }));
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/fieldconfigurations/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/fieldconfigurations/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static update(updatedFieldConfiguration: { value: Record<string, IValue> }, id: number): Promise<Record<string, unknown>> {
    return axios
      .patch(`${window.location.protocol}//${window.location.host}/api/v1/fieldconfigurations/${id}`, { payload: updatedFieldConfiguration })
      .then((res) => ({ ...res.data }));
  }

  add(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/fieldconfigurations`, {
        payload: {
          companyId: this.companyId,
          value: this.value,
        },
      })
      .then((res) => ({ ...res.data }));
  }
}
