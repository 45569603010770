/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledAutocompleteItem = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  :hover {
    background-color: ${cssVars.blue};
    color: white;
  }
`;
