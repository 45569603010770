/** @format */

import styled from '@emotion/styled';

/**
 * TODO: Double check this Giga-chad CSS actually works!!!
 */
const ElementMerge = styled.div`
  display: flex;
  width: 100%;

  > *:not(:first-child:not(style), style:first-child + *):not(:last-child) {
    border-left: none;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }

  > :first-child:not(style):not(:last-child),
  style:first-child + :nth-child(2):not(:last-child) {
    margin-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  > :last-child:not(:first-child:not(style), style:first-child + *) {
    border-left: none;
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export default ElementMerge;
