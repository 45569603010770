/** @format */

import styled from '@emotion/styled';

const StyledTagInput = styled.input`
  margin: 0;
  height: 100%;
  flex-grow: 1;
  border: none;
  padding: 8px;

  :hover,
  :focus {
    outline: none;
  }
`;

export default StyledTagInput;
