/** @format */

import styled from '@emotion/styled';

const StyledUserIconBig = styled.div`
  padding: 0;
  border: none;
  height: 80px;
  width: 80px;
  margin: 0 12px;
  border-radius: 40px;
  font-size: 28pt;
  background-color: blueviolet;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default StyledUserIconBig;
