/** @format */

import { RoleContext } from '@common/application/context/RoleProvider';
import { Currency } from '@common/model/apiClient/Currency';
import { Locale } from '@common/model/apiClient/Locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const icons = {
  'euro-sign': <FontAwesomeIcon icon={['fas', 'euro-sign']} />,
  'sterling-sign': <FontAwesomeIcon icon={['fas', 'sterling-sign']} />,
  'dollar-sign': <FontAwesomeIcon icon={['fas', 'dollar-sign']} />,
};

interface CurrencyContextValues {
  currencies: ICurrency[];
  selectedCurrency: ICurrency;
  selectedCurrencyIcon: typeof FontAwesomeIcon;
  changeCurrency: Function;
}

export interface ICurrency {
  id: string;
  name: string;
  iconName: string;
  icon: typeof FontAwesomeIcon;
}

const CurrencyContext = createContext<CurrencyContextValues | undefined>(undefined);

function CurrencyProvider(props) {
  const [currencies, setCurrencies] = useState<Array<ICurrency>>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency>();
  const [selectedCurrencyIcon, setSelectedCurrencyIcon] = useState<typeof FontAwesomeIcon>();
  const { locale } = useRouter();
  const role = useContext(RoleContext);

  useQuery(['currencies_not_deleted'], () => Currency.list('', [], {}, undefined, undefined), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    keepPreviousData: true,
    retry: 1,
    onSuccess: (data) => {
      setCurrencies(data.payload.map((c) => ({ id: c._source.iso4217, iconName: c._source.iconName, name: c._source.name, icon: icons[c._source.iconName] })));
    },
  });

  const localeData = useQuery(['selected_locale_get_locale', locale, role.selectedRole], () => Locale.get(locale), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    keepPreviousData: true,
    retry: 1,
  });

  useEffect(() => {
    if (localeData.isSuccess) {
      changeCurrency(localeData?.data?.payload?.defaultCurrencyId);
    }
  }, [localeData?.data, currencies, localeData.isSuccess]);

  const changeCurrency = (currencyId: string) => {
    const currency = currencies.find((c) => c.id === currencyId);
    setSelectedCurrency(currency);
    setSelectedCurrencyIcon(icons[currency?.iconName]);
  };

  return (
    <CurrencyContext.Provider
      value={{
        currencies,
        selectedCurrency,
        selectedCurrencyIcon,
        changeCurrency,
      }}
    >
      {props.children}
    </CurrencyContext.Provider>
  );
}

export { CurrencyProvider, CurrencyContext };
