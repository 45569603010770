/** @format */

import axios from 'axios';

export class Notification {
  id: number;
  event: string;
  table: string;
  createdAt: string;
  read: boolean;
  userId: string;
  href?: string;

  entityId: string;
  static list(userId: string) {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/notifications`, {
          params: {},
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static create(userId: string, notification: { table: string; event: string; entityId: string }) {
    if (typeof window !== 'undefined') {
      return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/notifications`, { payload: notification }).then((res) => ({ ...res.data }));
    }
    throw new Error();
  }
  static delete(notificationId: number) {
    if (typeof window !== 'undefined') {
      return axios
        .delete(`${window.location.protocol}//${window.location.host}/api/v1/notifications/${notificationId}`, {
          params: {},
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }
  static deleteAllForUser(userId: string) {
    if (typeof window !== 'undefined') {
      return axios
        .delete(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/notifications`, {
          params: {},
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static markRead(notificationId: number) {
    if (typeof window !== 'undefined') {
      return axios
        .patch(
          `${window.location.protocol}//${window.location.host}/api/v1/notifications/${notificationId}`,
          {
            payload: { read: true },
          },
          {
            params: {},
          },
        )
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static markAllRead(userId: string) {
    if (typeof window !== 'undefined') {
      return axios
        .patch(
          `${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/notifications`,
          { payload: { read: true } },
          {
            params: {},
          },
        )
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }
}
