/** @format */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FocusTrap from 'focus-trap-react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import { cssVars } from '../GlobalStyles';
import { StyledDialogue, StyledDialogueArea, StyledDialogueWrapper } from './styled';

enum DIALOGUE {
  INFO,
  ALERT,
  ERROR,
  SUCCESS,
}

function getBackgroundColor(variant: DIALOGUE) {
  switch (variant) {
    case DIALOGUE.ALERT:
      return '#dcdc38';
    case DIALOGUE.ERROR:
      return cssVars.red;
    case DIALOGUE.SUCCESS:
      return '#4cff4c';
    case DIALOGUE.INFO:
    default:
      return cssVars.blue;
  }
}

const InvisibleButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;

interface AddDialogue {
  message: string | React.ReactNode;
  buttons?: Array<{ text: string | React.ReactNode; onClick: (close: () => void) => void }>;
  variant?: DIALOGUE;
}

interface Dialogue {
  id: number;
  message: string | React.ReactNode;
  buttons?: Array<{ text: string; onClick: (close: () => void) => void }>;
  variant: DIALOGUE;
}

const defaultApi = {
  dialogues: [] as Dialogue[],
  show: (dialogue: AddDialogue) => {
    /*default*/
  },
  clearDialogue: (id: number) => {
    /*default*/
  },
};

export type DialoguesContextValue = typeof defaultApi;

/**
 * Create Context
 */
const DialoguesContext = React.createContext<DialoguesContextValue>(defaultApi);

function Dialogue({ variant, id, message, buttons = [], clearDialogue }: Dialogue & { clearDialogue: (id: number) => void }) {
  const [originUrl, setOriginUrl] = React.useState<string | undefined>();
  const router = useRouter();

  React.useEffect(() => {
    if (!originUrl) {
      setOriginUrl(router.pathname);
      return;
    } else {
      clearDialogue(id);
    }
  }, [router.pathname]);

  return (
    <StyledDialogue variant={variant} key={id}>
      <div
        css={css`
          padding: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: ${getBackgroundColor(variant)};
          color: white;
          font-weight: bold;
          font-size: 16pt;
        `}
      >
        <span>
          {variant === DIALOGUE.ERROR ? (
            <FormattedMessage defaultMessage="Error" description="Dialogue error text" />
          ) : variant === DIALOGUE.ALERT ? (
            <FormattedMessage defaultMessage="Alert" description="Dialogue Alert text" />
          ) : variant === DIALOGUE.SUCCESS ? (
            <FormattedMessage defaultMessage="Success" description="Dialogue Success text" />
          ) : (
            <FormattedMessage defaultMessage="Info" description="Dialogue Info text" />
          )}
        </span>
        <InvisibleButton>
          <FontAwesomeIcon
            css={css`
              margin-left: 12px;
              cursor: pointer;
              color: white;
            `}
            icon={['fas', 'multiply']}
            onClick={() => clearDialogue(id)}
          />
        </InvisibleButton>
      </div>
      <div
        css={css`
          padding: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <span>{message}</span>
      </div>
      <div
        css={css`
          width: 100%;
          padding: 16px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
        `}
      >
        {buttons.map((button) => (
          <Button key={button.text} onClick={() => button.onClick(() => clearDialogue(id))}>
            {button.text}
          </Button>
        ))}
      </div>
    </StyledDialogue>
  );
}

/**
 * Custom Dialogues Provider
 */
function DialoguesProvider({ children }: any) {
  // Dialogues queue is managed in local useState
  const [dialogues, setDialogues] = React.useState<Dialogue[]>(defaultApi.dialogues);

  // Method to push a new dialogue
  const show = React.useCallback(
    (dialogue: AddDialogue) => {
      setDialogues((dialogues) => [
        ...dialogues,
        {
          id: new Date().getTime(), // we use UIDs in the final ver
          variant: DIALOGUE.ERROR,
          ...dialogue,
        } as Dialogue,
      ]);
    },
    [setDialogues],
  );

  // Method to clear a dialogue
  const clearDialogue = React.useCallback(
    (id: number) => {
      const nextDialogues = dialogues.filter((n) => n.id !== id);
      setDialogues(nextDialogues);
    },
    [dialogues, setDialogues],
  );

  // Return Provider with full API
  const api = { dialogues, show, clearDialogue };
  return (
    <DialoguesContext.Provider value={api}>
      {children}
      {dialogues.length > 0 ? (
        <FocusTrap>
          <StyledDialogueWrapper>
            <StyledDialogueArea>
              <Dialogue {...dialogues[dialogues.length - 1]} clearDialogue={clearDialogue} />
            </StyledDialogueArea>
          </StyledDialogueWrapper>
        </FocusTrap>
      ) : null}
    </DialoguesContext.Provider>
  );
}

// Convenience import hook
function useDialogues() {
  return React.useContext(DialoguesContext);
}

export { useDialogues, DIALOGUE, DialoguesProvider, DialoguesContext };
