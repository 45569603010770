/** @format */

import React, { cloneElement, ReactElement } from 'react';
import styled from '@emotion/styled';
import { OverlayTriggerProps, useOverlayTriggerState } from 'react-stately';
import { Placement, useOverlayTrigger } from 'react-aria';
import Popover from './Popover';

interface PopoverTriggerProps {
  button: ReactElement;
  placement: Placement;
  label: string;
  children: any;
  arrowed?: boolean;
}

function PopoverTrigger({ label, children, ...props }: PopoverTriggerProps & OverlayTriggerProps) {
  let ref = React.useRef(null);
  let state = useOverlayTriggerState(props);
  let { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state, ref);

  return (
    <>
      {cloneElement(props.button, { ...triggerProps, onClick: state.open, ref: ref })}

      {state.isOpen && (
        <Popover {...props} triggerRef={ref} state={state} placement={'bottom'} arrowed={props.arrowed}>
          {React.cloneElement(children, overlayProps)}
        </Popover>
      )}
    </>
  );
}

export default PopoverTrigger;
