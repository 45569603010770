/** @format */
import { faCompassDrafting, faPalette, faScroll, faSortDown, faSortUp, faBanBug } from '@fortawesome/pro-duotone-svg-icons';
import { faFile, faFilePlus as faFilePlusL, faFileSignature, faPaperPlane, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faDownload,
  faFileExcel,
  faLink,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faSearch,
  faSort,
  faTrashUndo,
  faTrashXmark as faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArrowRightArrowLeft,
  faArrowsRotate,
  faAsterisk,
  faBank,
  faBars,
  faBell,
  faBinoculars,
  faBlockQuote,
  faBold,
  faBug,
  faBuilding,
  faCamera,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown as faChevronDownS,
  faChevronDown as fasChevronDown,
  faChevronLeft as faChevronLeftS,
  faChevronRight as faChevronRightS,
  faChevronUp as faChevronUpS,
  faCircleExclamation,
  faCircleNotch,
  faCircleQuestion,
  faClockRotateLeft,
  faCloudArrowDown,
  faCompassDrafting as faCompass,
  faCompress,
  faCopyright,
  faDollarSign,
  faDown,
  faDownload as faDLSolid,
  faEnvelope,
  faEuroSign,
  faExchange,
  faExpand,
  faFileCirclePlus,
  faFilePlus,
  faFloppyDisk,
  faFloppyDiskPen,
  faFolder,
  faFont,
  faGear,
  faH1,
  faH2,
  faH3,
  faHeader,
  faImage,
  faImageSlash,
  faIndustry,
  faItalic,
  faLanguage,
  faLink as faLinkS,
  faListOl,
  faListUl,
  faMagnifyingGlass,
  faMagnifyingGlassMinus as faMagnifyingGlassMinusS,
  faMagnifyingGlassPlus as faMagnifyingGlassPlusS,
  faMapLocationDot,
  faMaximize,
  faMemo,
  faMessageExclamation,
  faMinimize,
  faMinus,
  faMonitorWaveform,
  faMultiply,
  faPaintRoller,
  faPalette as fasPalette,
  faPaperclip,
  faPencil,
  faPercent,
  faPieChart,
  faPlus,
  faPrint,
  faRectangleList,
  faScroll as fasScroll,
  faShop,
  faSigma,
  faSpinner,
  faStar,
  faSterlingSign,
  faStrikethrough,
  faSymbols,
  faTextSlash,
  faTimesCircle,
  faTriangleExclamation,
  faUnderline,
  faUp,
  faUpload,
  faUsers,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';

const faIcons = [
  faSortUp,
  faSort,
  faSortDown,
  faAlignCenter,
  faAlignJustify,
  faAngleLeft,
  faAngleRight,
  faArrowsRotate,
  faArrowRightArrowLeft,
  faAsterisk,
  faBanBug,
  faBank,
  faBell,
  faBinoculars,
  faBlockQuote,
  faBold,
  faBug,
  faBuilding,
  faCamera,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronDownS,
  faChevronLeft,
  faChevronLeftS,
  faChevronRight,
  faChevronRightS,
  faChevronUp,
  faChevronUpS,
  faCircleQuestion,
  faCircleExclamation,
  faClockRotateLeft,
  faMessageExclamation,
  faCloudArrowDown,
  faCompass,
  faCompassDrafting,
  faCircleNotch,
  faClipboard,
  faCompress,
  faCopyright,
  faDLSolid,
  faDollarSign,
  faDown,
  faDownload,
  faEnvelope,
  faEuroSign,
  faExchange,
  faExpand,
  faFile,
  faFileCirclePlus,
  faFileExcel,
  faFilePlus,
  faFilePlusL,
  faFileSignature,
  faFloppyDisk,
  faFloppyDiskPen,
  faFolder,
  faFont,
  faGear,
  faHeader,
  faH1,
  faH2,
  faH3,
  faImage,
  faImageSlash,
  faIndustry,
  faItalic,
  faLanguage,
  faLink,
  faLinkS,
  faMagnifyingGlass,
  faMagnifyingGlassMinus,
  faMagnifyingGlassMinusS,
  faMagnifyingGlassPlus,
  faMagnifyingGlassPlusS,
  faMapLocationDot,
  faMaximize,
  faMemo,
  faMinimize,
  faMinus,
  faMonitorWaveform,
  faMultiply,
  faPaintRoller,
  faPalette,
  fasPalette,
  faPaperclip,
  faPaperPlane,
  faPencil,
  faPercent,
  faPieChart,
  faPlus,
  faPrint,
  faQuestionCircle,
  faRectangleList,
  faSearch,
  faScroll,
  fasScroll,
  faShop,
  faSigma,
  faSpinner,
  faStar,
  faSterlingSign,
  faStrikethrough,
  faSymbols,
  faTextSlash,
  faTrash,
  faTriangleExclamation,
  faUnderline,
  faUp,
  faUpload,
  faUsers,
  faBars,
  fasChevronDown,
  faListUl,
  faListOl,
  faAlignLeft,
  faAlignRight,
  faTimesCircle,
  faTrashUndo,
  faWrench,
];
export default faIcons;
