/** @format */

import ErrorBoundary from '@atoms/ErrorBoundary/ErrorBoundary';
import { ReactNode } from 'react';
import { StyledContentWrapper } from './styled';

interface ContentWrapperProps {
  layoutId: string;
  unpadded?: boolean;
  children: ReactNode;
  className?: string;
}

export function ContentWrapper(props: ContentWrapperProps) {
  return (
    <StyledContentWrapper unpadded={props.unpadded} initial={{ x: 0, opacity: 0 }} animate={{ opacity: 1 }} layoutId={props.layoutId} className={props.className}>
      {/*<StyledContentWrapper unpadded={props.unpadded} initial={{ x: 0, opacity: 0 }} animate={{ opacity: 1 }} layoutId="content-wrapper">*/}
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </StyledContentWrapper>
  );
}

export default ContentWrapper;
