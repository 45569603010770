/** @format */

import React from 'react';
import { StyledTagInput } from './styled';

interface TagInputProps {
  value: string;

  onChange(e: React.ChangeEvent<HTMLInputElement>): void;

  onBlur(e: React.FocusEvent<HTMLInputElement>): void;

  onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void;

  onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void;

  onClick(e: React.MouseEvent<HTMLInputElement>): void;
}

const TagInput = React.forwardRef<HTMLInputElement, TagInputProps>((props, ref) => {
  return <StyledTagInput {...props} ref={ref} />;
});

TagInput.displayName = 'TagInput';

export default TagInput;
