/** @format */

import ErrorFallback from '@molecules/ErrorFallback/ErrorFallback';
import React from 'react';

class ErrorBoundary extends React.Component<{}, { hasError: boolean; error: object; errorSent: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {}, errorSent: false };
  }

  logError = (error, errorInfo) => {
    // console.log(error, errorInfo);
    // const {data: {data: {version}} = axios.get('/api/v1/version');
  };

  resetError = () => {
    this.setState({ hasError: false });
    this.setState({ error: {} });
  };

  markSent = () => {
    this.setState({ errorSent: true });
    setTimeout(() => {
      this.setState({ errorSent: false });
    }, 10000);
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.logError(error, errorInfo);
    this.setState({ error: { error: error, errorInfo: errorInfo } });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback reset={() => this.resetError()} error={this.state.error} markSent={this.markSent} isSent={this.state.errorSent} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
