/** @format */
import { NotificationEvents } from '@common/application/enums/NotificationEvents';
import { Notification } from '@common/model/apiClient/Notification';
import { useRouter } from 'next/router';

export default function useNotificationActions(notification) {
  const router = useRouter();
  const getContactAction = (notification: Notification) => {
    // console.log(notification);
    const url = `/entities/${notification.table}/${notification.entityId}`;
    return () => router.push(url, url, { scroll: false });

    // switch (notification.event) {
    //   case NotificationEvents.PROPOSED:
    //     return () => router.push(url, url, { scroll: false });
    //   case NotificationEvents.APPROVED:
    //     return () => router.push(url, url, { scroll: false });
    //   default:
    //     return null;
    // }
  };

  const getResourceAction = (notification: Notification) => {
    if (notification.href) {
      return async () => {
        // Second router push is required to get the scroll behaviour to work
        await router.push(notification.href);
        await router.replace(notification.href);
      };
    }
    return null;
  };

  switch (notification.table) {
    case 'contact':
      return getContactAction(notification);
    case 'resource':
      return getResourceAction(notification);
    default:
      return getContactAction(notification);
    // return null;
  }
}
