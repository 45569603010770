/** @format */

import { Resource } from '@common/application/enums/Resource';
import { ResourceStatus } from '@common/application/enums/ResourceStatus';
import { ResourceUrl } from '@common/application/helpers/generateSignedUrlsForResults';
import uploadFileToSignedUrl from '@common/application/helpers/uploadFileToSignedUrl';
import { AttachmentTypeValue } from '@common/model/apiClient/AttachmentType';
import axios from 'axios';

export type TAttachment = ResourceUrl | { url: File; urlStatus: ResourceStatus };
export class Attachment {
  id: number;
  description: string;
  filename: string;
  attachment: TAttachment;
  documentId: number;
  attachmentTypeId: AttachmentTypeValue;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;

  constructor(description: string, filename: string, attachment: TAttachment, documentId: number, attachmentTypeId: AttachmentTypeValue) {
    this.description = description;
    this.filename = filename;
    this.attachment = attachment;
    this.documentId = documentId;
    this.attachmentTypeId = attachmentTypeId;
  }

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Attachment; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/attachments`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/attachments/${id}`).then((res) => ({ ...res.data }));
  }

  async add(): Promise<{ message: string; paginated: boolean; payload: Attachment }> {
    return axios
      .post(
        `${window.location.protocol}//${window.location.host}/api/v1/documents/${this.documentId}/attachments`,
        {
          payload: {
            meta: {
              description: this.description,
              filename: this.filename,
              documentId: this.documentId,
              attachmentTypeId: this.attachmentTypeId,
            },
            // file: this.attachment,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(async (res) => {
        const { signedUrl } = res.data.payload;
        await uploadFileToSignedUrl(signedUrl, this.attachment.url as File, res.data.payload, 'attachment', Resource.ATTACHMENT);
        return { ...res.data };
      })
      .catch((error) => {
        throw error;
      });
  }
}
