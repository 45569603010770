/** @format */

import styled from '@emotion/styled';

const StyledErrorPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default StyledErrorPage;
