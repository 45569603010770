/** @format */

import Base from '@common/application/styles/themes/Base';
import eProps from '@common/application/types/EmotionStyledProps';
import styled from '@emotion/styled';
import chroma from 'chroma-js';
import { ReactNode } from 'react';
import { cssVars } from '../../GlobalStyles';

export interface StyledTextProps {
  error?: boolean;
  bold?: boolean;
  style?: 'italic' | 'oblique';
  case?: 'none' | 'lower' | 'upper' | 'capital';
  decoration?: 'overline' | 'underline' | 'strike';
  danger?: boolean;
  warning?: boolean;
  success?: boolean;
  info?: boolean;
  light?: boolean;
  dark?: boolean;
  secondary?: boolean;
  link?: boolean;
  active?: boolean;
}

type esTextProps = StyledTextProps & eProps;

export interface TextProps extends StyledTextProps {
  children: ReactNode;
  copyable?: boolean;
  editable?: boolean;
  className?: string;

  onClick?(): void;
}

const d = (val: string) => chroma(val).darken(0.4).hex();

export const getColor = (props: esTextProps) => {
  if (props.secondary) {
    return props?.theme?.color?.text?.secondary ?? Base.color?.text?.secondary ?? cssVars.red;
  }
  if (props.link) {
    return props?.theme?.color?.text?.link ?? Base.color?.text?.link ?? cssVars.blue;
  }
  return props?.theme?.color?.text?.primary ?? Base.color?.text?.primary;
};

export const getHoverColor = (props: esTextProps) => {
  if (props.link) {
    return d(props?.theme?.color?.text?.link ?? Base.color?.text?.link ?? cssVars.blue);
  }
  return props?.theme?.color?.text?.primary ?? Base.color?.text?.primary;
};

export const getCursor = (props: esTextProps) => {
  if (props.link) {
    return 'pointer';
  }
  return 'initial';
};

export const StyledText = styled.div<StyledTextProps>`
  height: fit-content;
  width: fit-content;
  color: ${getColor};
  cursor: ${getCursor};
  position: relative;

  :hover {
    color: ${getHoverColor};
  }

  :hover > *:last-child {
    width: 100%;
  }
`;
