/** @format */

import { INPUT_TYPES, NUMERIC_KEYS } from '@common/modules/Keyboard';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ElementMerge from '@quarks/ElementMerge';
import TagContainer from '@quarks/TagContainer';
import TagText from '@quarks/TagText';
import Decimal from 'decimal.js';
import React, { KeyboardEventHandler, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import Tag from '../Tag';
import TagClear from '../TagClear';
import TagInput from '../TagInput';
import TagInputWrapper from '../TagInputWrapper';
import { StyledIconWrapper, StyledInput, StyledInputWrapper, StyledReactNodeInput, StyledSpinner } from './styled';

export interface InputProps {
  /**
   * onChange
   * @param {string} value
   */
  onChange: (value: string, noQuery?: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  className?: string;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  hasDecorator?: boolean;
  hasPostfixDecorator?: boolean;
  decorator?: ReactNode;
  placeholder?: string | IntlShape;
}

interface NumberInputProps {
  value?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  defaultValue?: number;
  maxValue?: number;
  onChange(value: number): void;
}

interface DecimalInputProps {
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  defaultValue?: Decimal;
  value?: Decimal;

  onChange(value: Decimal): void;
}

interface ReactNodeInputProps {
  className?: string;
  value?: React.ReactNode;
}

interface InputExtensions {
  Integer: React.FC<InputProps>;
  Number: React.FC<NumberInputProps>;
  Decimal: React.FC<DecimalInputProps>;
  Search: React.FC<InputProps>;
  Tag: React.FC<TagInputProps>;
  ReactNodeInput: React.FC<ReactNodeInputProps>;
}

interface InputType extends React.ForwardRefExoticComponent<InputProps & React.RefAttributes<HTMLInputElement>>, InputExtensions {}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  // props.value set = controlled input
  return (
    <ElementMerge>
      {!!props.decorator ? props.decorator : null}
      <StyledInput
        placeholder={props.placeholder as string}
        autoComplete="nope"
        hasDecorator={!!props.decorator || props.hasDecorator}
        hasPostfixDecorator={props.hasPostfixDecorator}
        ref={ref}
        value={props.value ?? ''}
        className={props.className}
        onChange={(event) => props.onChange(event.target.value)}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onClick={props.onClick}
        disabled={props.disabled}
      />
    </ElementMerge>
  );
}) as InputType;

Input.displayName = 'Input';

Input.Integer = function Integer(props: InputProps & { maxNumber: number }) {
  const [value, setValue] = useState(BigInt(props.defaultValue ?? 0));

  useEffect(() => {
    props.onChange(value.toString());
  }, [value]);

  const onInput = (event: SyntheticEvent) => {
    const e = event.nativeEvent as InputEvent;
    const allowedKey =
      NUMERIC_KEYS.includes(e.data ?? '') ||
      e.inputType === INPUT_TYPES.DELETE_CONTENT_BACKWARD ||
      e.inputType === INPUT_TYPES.DELETE_CONTENT_FORWARD ||
      e.inputType === INPUT_TYPES.DELETE_BY_CUT;

    const newVal = (event.target as HTMLInputElement).value;

    if (e.inputType === INPUT_TYPES.INSERT_FROM_PASTE) {
      if (!newVal.match(/^-?[1-9]\d*$/)) {
        return;
      }
    } else if (e.data === '-') {
      if (!newVal.match(/^-?[1-9]\d*$/)) {
        return;
      }
    } else if (!allowedKey) {
      return;
    } else if (props.maxNumber && BigInt(Number(newVal)) > props.maxNumber) {
      return;
    }

    setValue(BigInt(newVal));
  };
  return (
    <StyledInputWrapper className={props.className} onWheel={(event) => setValue((value) => (Math.sign(event.deltaY) === -1 ? value + BigInt(1) : value - BigInt(1)))}>
      <StyledInput
        value={props.value ?? value.toString()}
        onInput={onInput}
        onChange={(event) => {
          if (!props.maxNumber || Number(event.target.value) < props.maxNumber) {
            props.onChange(event.target.value);
          }
        }}
      />
      <StyledSpinner>
        <FontAwesomeIcon onClick={() => setValue((value) => value + BigInt(1))} icon={['fas', 'caret-up']} />
        <FontAwesomeIcon onClick={() => setValue((value) => value - BigInt(1))} icon={['fas', 'caret-down']} />
      </StyledSpinner>
    </StyledInputWrapper>
  );
};

Input.Number = function Integer(props: NumberInputProps) {
  const [value, setValue] = useState<string>(props.defaultValue?.toString() ?? '');
  const intl = useIntl();

  useEffect(() => {
    setValue(props.defaultValue.toString());
  }, [props.defaultValue]);

  const onInput = (event: React.FormEvent<HTMLInputElement>) => {
    const e = event.nativeEvent as InputEvent;
    const allowedKey =
      NUMERIC_KEYS.includes(e.data ?? '') ||
      e.data === '.' ||
      //e.data === ',' || TODO: Add support for comma separator somehow: https://formatjs.io/docs/react-intl/components#formattednumber this can probably just be a convert to float => render text with react-intl
      e.inputType === INPUT_TYPES.DELETE_CONTENT_BACKWARD ||
      e.inputType === INPUT_TYPES.DELETE_CONTENT_FORWARD ||
      e.inputType === INPUT_TYPES.DELETE_BY_CUT;

    const newVal = value === '0' ? e.data : (event.target as HTMLInputElement).value;

    if (!allowedKey) {
      return;
    }
    if (e.inputType === INPUT_TYPES.INSERT_FROM_PASTE) {
      if (isNaN(Number(newVal))) {
        return;
      }
    }
    if (e.data === '.' && value.includes('.')) {
      return;
    }
    if (props.maxValue && Number(newVal) > props.maxValue) return;
    setValue(newVal);
  };
  return (
    <StyledInputWrapper
      onWheel={(event) =>
        setValue((value) => {
          const v = Math.sign(event.deltaY) === -1 ? Math.min(props.maxValue ?? Infinity, Number(value) + 1) : Number(value) - 1;
          props.onChange(v);
          return v.toString();
        })
      }
    >
      <StyledInput
        className={props.className}
        onBlur={() => {
          setValue((value) => {
            const v = isNaN(Number(value)) ? 0 : Number(value);
            props.onChange(v);
            return v.toString();
          });
        }}
        onInput={onInput}
        value={intl.formatNumber(Number(value))}
        //        onChange={onInput}
      />
      <StyledSpinner>
        <FontAwesomeIcon
          onClick={() =>
            setValue((value) => {
              const v = Number(value) + 1;
              props.onChange(v);
              return v.toString();
            })
          }
          icon={['fas', 'caret-up']}
        />
        <FontAwesomeIcon
          onClick={() =>
            setValue((value) => {
              const v = Number(value) - 1;
              props.onChange(v);
              return v.toString();
            })
          }
          icon={['fas', 'caret-down']}
        />
      </StyledSpinner>
    </StyledInputWrapper>
  );
};

Input.Decimal = function DecimalInput(props: DecimalInputProps) {
  const [value, setValue] = useState<string>(props.defaultValue?.toString() ?? '0');

  useEffect(() => {
    setValue(props.value.toString());
  }, [props.value]);

  const onInput = (event: React.FormEvent<HTMLInputElement>) => {
    const e = event.nativeEvent as InputEvent;
    const allowedKey =
      NUMERIC_KEYS.includes(e.data ?? '') ||
      e.data === '.' ||
      //e.data === ',' || TODO: Add support for comma separator somehow: https://formatjs.io/docs/react-intl/components#formattednumber
      e.inputType === INPUT_TYPES.DELETE_CONTENT_BACKWARD ||
      e.inputType === INPUT_TYPES.DELETE_CONTENT_FORWARD ||
      e.inputType === INPUT_TYPES.DELETE_BY_CUT;

    const newVal = value === '0' ? e.data : (event.target as HTMLInputElement).value;

    if (!allowedKey) {
      return;
    }
    if (e.inputType === INPUT_TYPES.INSERT_FROM_PASTE) {
      if (isNaN(Number(newVal))) {
        return;
      }
    }
    if (e.data === '.' && value.includes('.')) {
      return;
    }
    setValue(newVal);
  };

  const _onWheel = useCallback(
    (event) => {
      const v = Math.sign(event.deltaY) === -1 ? new Decimal(value).plus(1) : new Decimal(value).minus(1);
      props.onChange(v);
      setValue(v.toString());
    },
    [props, value],
  );

  const _onBlur = useCallback(() => {
    const v = value === '' || isNaN(Number(value)) ? new Decimal(0) : new Decimal(value);
    props.onChange(v);
    setValue(v.toString());
  }, [props, value]);

  const _onInc = useCallback(() => {
    const v = new Decimal(value).plus(1);
    props.onChange(v);
    setValue(v.toString());
  }, [props, value]);

  const _onDec = useCallback(() => {
    const v = new Decimal(value).minus(1);
    props.onChange(v);
    setValue(v.toString());
  }, [props, value]);

  return (
    <StyledInputWrapper onWheel={_onWheel}>
      <StyledInput
        className={props.className}
        onBlur={_onBlur}
        onInput={onInput}
        value={value}

        //        onChange={onInput}
      />
      <StyledSpinner>
        <FontAwesomeIcon onClick={_onInc} icon={['fas', 'caret-up']} />
        <FontAwesomeIcon onClick={_onDec} icon={['fas', 'caret-down']} />
      </StyledSpinner>
    </StyledInputWrapper>
  );
};

Input.Search = function (props: InputProps) {
  return (
    <StyledInputWrapper className={props.className}>
      <StyledIconWrapper>
        <FontAwesomeIcon icon={['far', 'search']} />
      </StyledIconWrapper>
      <StyledInput
        css={css`
          padding-left: 29px;
        `}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </StyledInputWrapper>
  );
};

Input.Search.displayName = 'Search';

type RemoveEvent = React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLInputElement>;

export interface InputTag {
  key: symbol;
  value: string;

  remove(e: RemoveEvent): void;
}

export interface TagInputProps {
  tags: Array<InputTag>;
  defaultValue?: string;
  onTagAdded: () => void;
  onValueChanged: (value: string) => void;
}

Input.Tag = function InputTag(props: TagInputProps) {
  const [isFocussed, setIsFocussed] = useState(false);
  const [value, _setValue] = useState(props.defaultValue ?? '');

  const setValue = useCallback(
    (v) => {
      _setValue(v);
      props.onValueChanged(v);
    },
    [props],
  );

  const input = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => {
    input.current?.focus();
    setIsFocussed(true);
  }, []);

  const addTag = useCallback(() => {
    if (value !== '') {
      setValue('');
      props.onTagAdded();
    }
  }, [props, setValue, value]);

  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (value === '' && e.key === ' ') {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [value],
  );

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        addTag();
      } else if (e.key === 'Backspace' && value === '' && props.tags.length > 0) {
        props.tags.slice(-1)[0].remove(e);
      }
    },
    [addTag, props.tags, value],
  );

  return (
    <TagInputWrapper focussed={isFocussed} onClick={onClick}>
      <TagContainer tagCount={props.tags.length}>
        {props.tags.map((tag) => (
          <Tag key={tag.value}>
            <TagText>{tag.value}</TagText>
            <TagClear onClick={tag.remove}>
              <FontAwesomeIcon icon={['fas', 'multiply']} />
            </TagClear>
          </Tag>
        ))}
      </TagContainer>
      <TagInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          addTag();
          setIsFocussed(false);
        }}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onClick={() => setIsFocussed(true)}
        ref={input}
      />
    </TagInputWrapper>
  );
};

function ReactNodeInput(props: ReactNodeInputProps) {
  // props.value set = controlled input
  return <StyledReactNodeInput className={props.className}>{props.value ?? <span> </span>}</StyledReactNodeInput>;
}

Input.ReactNodeInput = ReactNodeInput;

export default Input;
