/** @format */

import styled from '@emotion/styled';

export const StyledNotificationArea = styled.aside`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  user-select: none;
  pointer-events: none;
  justify-content: flex-end;
  align-items: center;
  z-index: 99999;
`;
