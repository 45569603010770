/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import eProps from '@common/application/types/EmotionStyledProps';
import chroma from 'chroma-js';

interface ThemeProps {
  warning?: boolean;
  error?: boolean;
  disabled?: boolean;
  active?: boolean;
}

type esComponentProps = ThemeProps & eProps;

const d = (val: string) => chroma(val).darken(0.2).hex();

//<editor-fold desc="Style Getters">
const getBorderColor = (props: esComponentProps) => {
  if (props.error) {
    return cssVars.red;
  }
  if (props.warning) {
    return cssVars.yellow;
  }
  return cssVars.lightGrey;
};

const getBackgroundColor = (props: esComponentProps) => {
  if (props.error) {
    return cssVars.red;
  }
  if (props.warning) {
    return cssVars.yellow;
  }
  return cssVars.white;
};

const getColor = (props: esComponentProps) => {
  if (props.error || props.warning) {
    return cssVars.yellow;
  }
  return cssVars.black;
};

const getHoverBorderColor = (props: esComponentProps) => {
  if (props.warning || props.error) {
    return cssVars.white;
  }
  return cssVars.blue;
};

const getHoverColor = (props: esComponentProps) => {
  if (props.warning || props.error) {
    return cssVars.white;
  }
  return cssVars.blue;
};

const getActiveBorderColor = (props: esComponentProps) => {
  if (props.warning) {
    return d(cssVars.white);
  }
  return d(cssVars.blue);
};

const getActiveColor = (props: esComponentProps) => {
  if (props.warning) {
    return d(cssVars.white);
  }
  return d(cssVars.blue);
};
//</editor-fold>

export const styles = {
  borderColor: getBorderColor,
  backgroundColor: getBackgroundColor,
  color: getColor,
  hover: {
    borderColor: getHoverBorderColor,
    color: getHoverColor,
  },
  active: {
    borderColor: getActiveBorderColor,
    color: getActiveColor,
  },
};
