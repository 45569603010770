/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import useOnClickOutside from '@common/application/hooks/useOnClickOutside/src';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutocompleteModal from '@molecules/AutocompleteModal/AutocompleteModal';
import ElementMerge from '@quarks/ElementMerge';
import levenshtein from 'js-levenshtein';
import React, { ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Input, { InputProps } from '../Input';
import { PrefixedInput } from '../PrefixedInput';
import Scrollbar from '../Scrollbar';
import { StyledAutocomplete, StyledAutocompleteItem, StyledAutocompleteItems, StyledAutocompleteList } from './styled';

export type UnsortedOption<Type> = { value: string; id: string | number; data?: Type; render: React.FC };

/**
 * @param options {Array<UnsortedOption>} An array of the options to be displayed by the autocomplete
 * @param {string} value the search query
 * @param selected {string} The currently selected option
 * @param  onSelected  function to handle selection of an option
 * @param manual {boolean} (optional) whether the autocomplete logic is handled manually or internally by the component. E.g. set to true if autocomplete is handled by ElasticSearch
 * @param decorator {ReactNode} (optional) an icon for the input (e.g. a magnifying glass)
 * @param disabled {boolean} (optional) whether the input is disabled
 * @param onEmptyResultsConfig {{ message: EmotionJSX.Element; onClick: () => void }} (optional) a message and click behaviour to use when no results are returned
 * @param tabular {boolean} (optional) if this is selected then the results will be displayed in a modal instead of the standard dropdown. Use when there is more than one piece of information displayed for each result
 * */
export interface AutocompleteProps<Type> extends InputProps {
  options: Array<UnsortedOption<Type>>;
  selected: string;
  manual?: boolean;
  decorator?: ReactNode;
  disabled?: boolean;
  onEmptyResultsConfig?: { message: EmotionJSX.Element; onClick: () => void };
  tabular?: boolean;
  onSelected(option: AutocompleteOption<Type>): void;
}

export type AutocompleteOption<Type> = { value: string; id: string | number; ld?: number; data?: Type; render: React.FC };

const StyledClearButton = styled.span`
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${cssVars.white};
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${cssVars.black};
`;

const Autocomplete = function <Type>(props: AutocompleteProps<Type>) {
  const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef();
  const intl = useIntl();

  const autocomplete = useRef<HTMLDivElement>(null);

  const onLoseFocus = useCallback(() => {
    if (showModal) return;
    // ensure that the input displays the currently selected option
    props.onChange(props.selected ?? '', true);
    setActive(false);
  }, [props.onChange, showModal]);

  const deleteValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onChange('');
    props.onSelected({ id: null, value: '', render: () => <span></span> });
  };

  useOnClickOutside(autocomplete, onLoseFocus); // TODO: handle unselected input

  const openModal = () => {
    setShowModal(true);
    // inputRef.current.focus();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useLayoutEffect(() => {
    if (props.tabular) {
      onLoseFocus();
    }
  }, [onLoseFocus, showModal]);

  const inputMapper = useCallback(
    (option: AutocompleteOption<Type>) => {
      return {
        ...option,
        ld: (props.value?.length ?? 0) > 1 ? levenshtein(option.value, props.value ?? '') : 0,
      };
    },
    [props.value],
  );

  const filter = useCallback((option: AutocompleteOption<Type>) => {
    return (option.ld ?? 0) < 4;
  }, []);

  const sorter = useCallback((a: AutocompleteOption<Type>, b: AutocompleteOption<Type>) => {
    return (a.ld ?? 0) > (b.ld ?? 0) ? 1 : a.ld === b.ld ? 0 : -1;
  }, []);

  const selectOption = (option: AutocompleteOption<Type>) => {
    props.onSelected(option);
    props.onChange(option.value);
  };

  const outputMapper = useCallback(
    (option: AutocompleteOption<Type>) => {
      return (
        <StyledAutocompleteItem
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            selectOption(option);
            setActive(false);
            event.stopPropagation();
            event.preventDefault();
          }}
          key={option.id}
        >
          <option.render />
        </StyledAutocompleteItem>
      );
    },
    [props],
  );

  const list = useMemo(() => {
    if (active) {
      return (
        <StyledAutocompleteList>
          <Scrollbar disableXScroll>
            <StyledAutocompleteItems>
              {props.manual ? props.options?.map(outputMapper) : props.options?.map(inputMapper).filter(filter).sort(sorter).map(outputMapper)}
              {props.options?.length < 1 && (
                <StyledAutocompleteItem onClick={props.onEmptyResultsConfig?.onClick ?? null}>
                  {props.onEmptyResultsConfig?.message ?? (
                    <FormattedMessage defaultMessage={'No Results Found'} description={'Notification that a search did not find any results'} />
                  )}
                </StyledAutocompleteItem>
              )}
            </StyledAutocompleteItems>
          </Scrollbar>
        </StyledAutocompleteList>
      );
    }
    return null;
  }, [active, props.manual, props.options, props.onEmptyResultsConfig?.onClick, props.onEmptyResultsConfig?.message, outputMapper, inputMapper, filter, sorter]);

  return (
    <>
      <StyledAutocomplete ref={autocomplete}>
        <ElementMerge>
          <PrefixedInput icon={<FontAwesomeIcon icon={['far', 'search']} />}>
            <Input
              ref={inputRef}
              value={props.value}
              onChange={(v) => {
                if (v !== props.value) {
                  props.onChange(v);
                  setActive(true);
                }
              }}
              disabled={props.disabled}
              onFocus={() => {
                setActive(true);
                if (props.tabular) {
                  // openModal();
                }
              }}
              onClick={props.tabular ? openModal : null}
              placeholder={intl.formatMessage({ defaultMessage: 'Start typing to search...' })}
              // onClick={props.tabular ? () => openModal() : null}
            />
            {props.selected && (
              <StyledClearButton onClick={deleteValue}>
                <FontAwesomeIcon icon={['fas', 'multiply']} />
              </StyledClearButton>
            )}
          </PrefixedInput>
        </ElementMerge>
        {!props.tabular && list}
      </StyledAutocomplete>
      {props.tabular && (
        <AutocompleteModal
          state={{
            isOpen: showModal,
            close: () => setShowModal(false),
            open: () => setShowModal(true),
            setOpen: (bool) => setShowModal(bool),
            toggle: () => setShowModal((b) => !b),
          }}
          search={props.value}
          setSearch={props.onChange}
          options={props.options}
          onSelected={selectOption}
        />
      )}
    </>
  );
};

export default Autocomplete;
