/** @format */

import React from 'react';

function UseKeyString() {
  const processKeypress = (e: KeyboardEvent) => {
    return `${e.ctrlKey ? 'Ctrl+' : ''}${e.altKey ? 'Alt+' : ''}${e.shiftKey ? 'Shift+' : ''}${e.key.toUpperCase()}`;
  };

  const parseKeyString = (keyString: string) => {
    return {
      ctrlKey: keyString.includes('Ctrl'),
      altKey: keyString.includes('Alt'),
      shiftKey: keyString.includes('Shift'),
      key: keyString[keyString.length - 1],
      keyString: keyString,
    };
  };

  return { processKeypress, parseKeyString };
}

export default UseKeyString;
