/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';
import { NOTIFICATION } from '../index';

interface StyledNotificationProps {
  variant: NOTIFICATION;
}

function getBackgroundColor(variant: NOTIFICATION) {
  switch (variant) {
    case NOTIFICATION.INFO:
      return cssVars.blue;
    case NOTIFICATION.ALERT:
      return cssVars.yellow;
    case NOTIFICATION.ERROR:
      return cssVars.red;
    case NOTIFICATION.SUCCESS:
      return cssVars.green;
    default:
      return cssVars.green;
  }
}

function getColor(variant: NOTIFICATION) {
  switch (variant) {
    case NOTIFICATION.INFO:
      return 'white';
    case NOTIFICATION.ALERT:
      return 'black';
    case NOTIFICATION.ERROR:
      return 'white';
    case NOTIFICATION.SUCCESS:
      return 'black';
    default:
      return 'black';
  }
}

export const StyledNotification = styled.div<StyledNotificationProps>`
  user-select: none;
  pointer-events: all;
  background-color: ${(props) => getBackgroundColor(props.variant)};
  //padding: 16px 20px;
  margin: 8px;
  border-radius: 3px;
  z-index: 999;
  //box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  color: ${(props) => getColor(props.variant)};
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  > div {
    margin: 0 20px 20px 20px;
  }
`;
