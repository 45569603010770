/** @format */

import Modal from '@atoms/Modal';
import { AutocompleteOption, UnsortedOption } from '@atoms/Autocomplete';
import Dialog from '@atoms/Dialog';
import Input from '@atoms/Input/Input';
import { PrefixedInput } from '@atoms/PrefixedInput';
import Scrollbar from '@atoms/Scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledAutocompleteModalBody, StyledAutocompleteModalList, StyledAutocompleteModalListItem } from '@molecules/AutocompleteModal/StyledAutocompleteModal';
import ElementMerge from '@quarks/ElementMerge';
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { OverlayTriggerState } from 'react-stately';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from '@emotion/react';

/**
 * @param {boolean} [wide]  Whether the modal should be regular width
 * @param {string} search Value of searchbox
 * @param {(v: string) => void} setSearch search value setter
 * @param {Array<UnsortedOption>} options The available options to be displayed
 * @param {(option: AutocompleteOption)=>void} onSelected function that is called when an option is selected
 *
 * */
interface IAutocompleteModalProps<Type> {
  wide?: boolean;
  search: string;
  setSearch(v: string): void;
  options: Array<UnsortedOption<Type>>;
  className?: string;
  onSelected(option: AutocompleteOption<Type>): void;
  state: OverlayTriggerState;
}

const AutocompleteModal = function <Type>(props: IAutocompleteModalProps<Type>, forwardedRef) {
  const inputRef = useRef<HTMLInputElement>();
  const intl = useIntl();

  //useLayoutEffect to make sure the search text is cleared before the modal is rendered
  // useEffect(() => {
  useLayoutEffect(() => {
    if (props.state.isOpen && inputRef.current) {
      // props.setSearch('');
      inputRef.current.focus();
    }
    //  DO NOT CHANGE THIS TO JUST PROPS
  }, [props.state.isOpen]);

  return (
    <Modal state={props.state} isDismissable>
      <Dialog
        onClose={props.state.close}
        title={''}
        wide={props.wide}
        css={css`
          overflow: hidden;
        `}
        primary
        disableYScroll
      >
        <StyledAutocompleteModalBody
          css={css`
            overflow: hidden;
          `}
        >
          <ElementMerge>
            <PrefixedInput icon={<FontAwesomeIcon icon={['far', 'search']} />}>
              <Input onChange={props.setSearch} value={props.search} ref={inputRef} placeholder={intl.formatMessage({ defaultMessage: 'Start typing to search...' })} />
            </PrefixedInput>
          </ElementMerge>
          {/*@ts-ignore*/}
          {/*<AccountAutocompleteTable options={props.options} onSelect={(event, option) => onClickRow(event, option)} />*/}
          <StyledAutocompleteModalList>
            <Scrollbar>
              {!!props.options?.length ? (
                props.options?.map((option) => (
                  <StyledAutocompleteModalListItem
                    layout
                    key={option.id}
                    onClick={(event: React.MouseEvent<HTMLLIElement>) => {
                      event.stopPropagation();
                      event.preventDefault();
                      props.onSelected(option);
                      props.state.close();
                    }}
                  >
                    {option.render(null)}
                  </StyledAutocompleteModalListItem>
                ))
              ) : (
                <StyledAutocompleteModalListItem layout key={'notFound'} onClick={() => {}}>
                  <FormattedMessage defaultMessage={'No Results Found'} description={'Notification that a search did not find any results'} />
                </StyledAutocompleteModalListItem>
              )}
            </Scrollbar>
          </StyledAutocompleteModalList>
        </StyledAutocompleteModalBody>
      </Dialog>
    </Modal>
  );
};

export default AutocompleteModal;
