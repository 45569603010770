/** @format */

import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import React from 'react';

function BellIcon({ animate }) {
  const generateRotationArray = (startAngle: number, inverted?: boolean) => {
    const steps = 15;
    const increment = startAngle / steps;
    let rotationArray = [];

    for (let i = 0; i <= steps; i++) {
      const val = startAngle - increment * i;
      const transformedVal = inverted ? val * -1 : val;
      rotationArray.push(i % 2 === 0 ? transformedVal : transformedVal * -1);
    }
    return rotationArray;
  };

  return (
    <motion.svg
      version="1.1"
      viewBox="0 0 169.32 135.47"
      xmlns="http://www.w3.org/2000/svg"
      style={{ originX: '50%', originY: '50%' }}
      animate={animate ? { scale: [1, 1.1, 1, 1] } : { scale: 1 }}
      transition={{ duration: 2 }}
    >
      <g transform="translate(-15.275 -137.73)" strokeWidth=".26458">
        <motion.path
          css={css`
            transform-origin: center;
            //transform: rotate(20deg);
          `}
          initial={{ rotate: 0 }}
          animate={animate ? { rotate: generateRotationArray(25) } : { rotate: 0 }}
          transition={{ duration: 2 }}
          d="m142.25 192.77c0-20.556-14.414-37.018-33.851-41.053v-5.5139a8.4667 8.4667 0 1 0-16.933 0v5.5139c-19.426 4.0349-33.84 20.497-33.84 41.053 0 27.07-9.562 35.33-14.676 40.82a8.2762 8.2762 0 0 0-2.2781 5.7468c0.0291 4.3418 3.4396 8.4667 8.4905 8.4667h101.55c5.0562 0 8.4667-4.1249 8.4905-8.4667a8.2629 8.2629 0 0 0-2.2781-5.7468c-5.1144-5.4901-14.676-13.75-14.676-40.82z"
        />
        <motion.path
          css={css`
            transform-origin: bottom;
          `}
          style={{ originX: '50%', originY: '50%' }}
          initial={{ rotate: '0deg', transformOrigin: 'top' }}
          animate={animate ? { rotate: generateRotationArray(7, true) } : { rotate: 0 }}
          transition={{ duration: 2 }}
          d="m99.935 273.2a16.933 16.933 0 0 0 16.933-16.933h-33.867a16.933 16.933 0 0 0 16.933 16.933z"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={animate ? { opacity: [0, 1, 1, 1, 0.5, 0] } : { opacity: 0 }}
          transition={{ duration: 2 }}
          d="m178.25 182.19h-16.933a6.35 6.35 0 0 0 0 12.7h16.933a6.35 6.35 0 0 0 0-12.7z"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={animate ? { opacity: [0, 1, 1, 1, 0.5, 0] } : { opacity: 0 }}
          transition={{ duration: 2 }}
          d="m153.1 164.2a6.2971 6.2971 0 0 0 3.175-0.85196l14.663-8.4667a6.35 6.35 0 0 0-6.35-10.996l-14.663 8.4667a6.35 6.35 0 0 0 3.175 11.848z"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={animate ? { opacity: [0, 1, 1, 1, 0.5, 0] } : { opacity: 0 }}
          transition={{ duration: 2 }}
          d="m28.937 154.88 14.663 8.4667a6.35 6.35 0 1 0 6.35-10.996l-14.663-8.4667a6.35 6.35 0 1 0-6.35 10.996z"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={animate ? { opacity: [0, 1, 1, 1, 0.5, 0] } : { opacity: 0 }}
          transition={{ duration: 2 }}
          d="m44.902 188.54a6.35 6.35 0 0 0-6.35-6.35h-16.933a6.35 6.35 0 0 0 0 12.7h16.933a6.35 6.35 0 0 0 6.35-6.35z"
        />
      </g>
    </motion.svg>
  );
}

export default BellIcon;
