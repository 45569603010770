/** @format */

import { Resource } from '@common/application/enums/Resource';
import { ResourceUrl } from '@common/application/helpers/generateSignedUrlsForResults';
import uploadFileToSignedUrl from '@common/application/helpers/uploadFileToSignedUrl';
import axios from 'axios';

export class Help {
  id;
  companyId;
  locale;
  title;
  description;
  url: ResourceUrl;
  helpTypeId;
  embed;
  createdat;
  updatedat;
  deletedAt;

  constructor(companyId, locale, title, description, url: ResourceUrl, helpTypeId, embed) {
    this.companyId = companyId;
    this.locale = locale;
    this.title = title;
    this.description = description;
    this.url = url;
    this.helpTypeId = helpTypeId;
    this.embed = embed;
  }

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Help; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/help`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/help/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/help/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static update(
    updatedHelp: {
      companyId: number;
      localeId: string;
      title: string;
      description: string;
      url: string;
      helpTypeId: string;
      embed: boolean;
    },
    id: number,
  ): Promise<Record<string, unknown>> {
    return axios.patch(`${window.location.protocol}//${window.location.host}/api/v1/help/${id}`, { payload: updatedHelp }).then((res) => ({ ...res.data }));
  }

  add(ext: string, name: string): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/help`, {
        payload: {
          meta: {
            companyId: Number(this.companyId),
            localeId: this.locale,
            title: this.title,
            description: this.description,
            helpTypeId: this.helpTypeId,
            embed: this.embed,
          },
          file: { dataPresent: !!this.url, name: name },
          document,
          ext,
          // name,
        },
      })
      .then(async (res) => {
        const { signedUrl } = res.data.payload;
        await uploadFileToSignedUrl(signedUrl, this.url.url as File, res.data.payload, 'url', Resource.HELP);
        return { ...res.data };
      });
  }
}
