/** @format */

import BellIcon from '@atoms/BellIcon';
import NotificationBadge from '@atoms/NotificationBadge';
import PopoverTrigger from '@atoms/PopoverTrigger';
import { Notification } from '@common/model/apiClient/Notification';
import styled from '@emotion/styled';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import NotificationMenu from '@molecules/NotificationMenu';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

interface NotificationButtonProps {}

const ButtonWrapper = styled.span<{ notificationCount: number }>`
  cursor: pointer;
  position: relative;
  > svg {
    display: block;
    height: 32px;
    width: 32px;
  }
`;

function NotificationButton(props: NotificationButtonProps) {
  const [notificationCount, setNotificationCount] = useState(0);
  const session = useSecureSession<BaseRealmRole, BaseRole>();

  const notifications = useQuery([session?.data?.user?.id, 'user_notification_query'], () => Notification.list(session?.data?.user?.id), {
    onSuccess: (d) => {
      setNotificationCount(d.payload.filter((n) => !n.read).length);
    },
  });

  const button = () => (
    <ButtonWrapper notificationCount={notificationCount}>
      {/*<BellIcon animate={false} />*/}
      <BellIcon animate={!!notificationCount} />
      {!!notificationCount && <NotificationBadge notificationCount={notificationCount} />}
    </ButtonWrapper>
  );

  return (
    <PopoverTrigger button={button()} placement={'bottom'} label={'Open Notifications'} arrowed>
      <NotificationMenu notifications={notifications.data?.payload} />
    </PopoverTrigger>
  );
}

export default NotificationButton;
