/** @format */

export enum Resource {
  ACCOUNT_LOGO = 'accountLogo',
  COMPANY_LOGO = 'companyLogo',
  COMPANY_BRAND = 'companyBrand',
  ATTACHMENT = 'attachment',
  DOCUMENT_IMAGE = 'documentImage',
  HELP = 'help',
}
