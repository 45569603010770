/** @format */

import { RefObject, useEffect, useRef } from 'react';

// The originButton prop is the button element that opens the portal element - this allows clicking that button to shut the portal as well as open it
export default function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void,
  config: { ignoredElements?: HTMLElement[] | undefined; touchEnabled?: boolean } = {},
) {
  const originInsideRef = useRef<boolean>(false);
  const originScrollbarRef = useRef<boolean>(false);
  useEffect(
    () => {
      const downListener = (event: MouseEvent | TouchEvent) => {
        if (!ref?.current) return;
        // Set whether the click started inside to later compare with the area where the click ended
        // This is used to stop unwanted closing from dragging outside the box, and also allows the user to cancel the close by dragging back inside the box
        originInsideRef.current = ref.current.contains(event.target as Node);
        //Check if click starts in scrollbar
        // @ts-ignore
        originScrollbarRef.current = 'clientX' in event && event.clientX >= document.scrollingElement.scrollWidth;
      };

      const upListener = (event: MouseEvent | TouchEvent) => {
        // If the event target is an element in the array of ignored elements, or is one of their descendants then do nothing
        // This is to stop clickOutside from shutting the portal, then the button toggling it back open
        if (config.ignoredElements?.map((el) => el?.contains(event.target as Node)).includes(true)) {
          return;
        }

        // Do nothing if clicking ref's element or descendent elements MODALS WONT OPEN WITHOUT THIS?
        const modalRoot = document.getElementById('modal-root');
        const dialogueRoot = document.getElementById('dialogue-root');
        const notificationRoot = document.getElementById('notification-root');
        const freePositionRoot = document.getElementById('free-position-root');
        const t = event.target as Node;

        // If the click started inside and dragged outside or vice versa we don't want the modal to close
        const differentOrigins = ref.current && originInsideRef.current !== !!ref?.current?.contains(t);
        const wasScrollbarClicked = originScrollbarRef.current || ('clientX' in event && event.clientX >= document.scrollingElement.scrollWidth);
        const multipleModals = modalRoot?.children?.length > 1;
        if (multipleModals) {
          let lastModal = modalRoot.children[modalRoot.children.length - 1];
          if (lastModal.contains(t)) return;
        }
        if (
          !ref?.current ||
          ref?.current.contains(t) ||
          freePositionRoot.contains(t) ||
          // modalRoot.contains(t) ||
          // dialogueRoot.contains(t) ||
          notificationRoot.contains(t) ||
          differentOrigins ||
          wasScrollbarClicked
        ) {
          return;
        }

        handler(event);
      };
      document.addEventListener('mousedown', downListener, true);
      document.addEventListener('mouseup', upListener, true);
      if (config.touchEnabled) {
        document.addEventListener('touchstart', downListener, true);
        document.addEventListener('touchend', upListener, true);
      }
      return () => {
        document.removeEventListener('mousedown', downListener, true);
        document.removeEventListener('mouseup', upListener, true);
        if (config.touchEnabled) {
          document.removeEventListener('touchstart', downListener, true);
          document.removeEventListener('touchend', upListener, true);
        }
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, config.ignoredElements],
  );
}
