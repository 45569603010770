/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import React from 'react';

interface CarouselBreadcrumbsProps {
  elements: Array<{ id: string | number; element: EmotionJSX.Element }>;
  selectedIdx: number;
  onSelect(idx: number): void;
}

const StyledCarouselBreadcrumbs = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 6px;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  z-index: 99;
`;

const BreadCrumb = styled.span<{ selected: boolean }>`
  cursor: pointer;
  border-radius: 50vh;
  height: 4px;
  aspect-ratio: 1;
  background: ${(props) => (props.selected ? cssVars.black : 'none')};
  border: 1px solid ${cssVars.black};
`;

function CarouselBreadcrumbs(props: CarouselBreadcrumbsProps) {
  return (
    <StyledCarouselBreadcrumbs>
      {props.elements.map((e, idx) => (
        <BreadCrumb selected={props.selectedIdx === idx} onClick={() => props.onSelect(idx)} key={`carouselBreadcrumb${e.id}`} />
      ))}
    </StyledCarouselBreadcrumbs>
  );
}

export default CarouselBreadcrumbs;
