/** @format */

import styled from '@emotion/styled';

const StyledPage = styled.div`
  margin: 0;
  padding: 0;
  border: none;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  //position: fixed;
  scroll-behavior: unset;
`;

export default StyledPage;
