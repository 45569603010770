/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { Theme } from '@emotion/react';

const base: Theme = {
  color: {
    background: {
      primary: cssVars.white,
      secondary: cssVars.silverGrey,
      error: cssVars.red,
      warning: cssVars.yellow,
      hover: cssVars.blue,
    },
    border: {
      primary: cssVars.lightGrey,
      error: cssVars.red,
      warning: cssVars.yellow,
    },
    text: {
      link: cssVars.blue,
      primary: cssVars.black,
      secondary: '#848484',
      error: cssVars.red,
      warning: cssVars.yellow,
      light: cssVars.white,
    },
    pseudo: {
      hover: cssVars.blue,
      active: '',
    },
  },
};

export default base;
