/** @format */

import React, { ReactNode } from 'react';
import { StyledTagClear } from './styled';

interface TagClearProps {
  onClick(e: React.MouseEvent<HTMLSpanElement>): void;

  children: ReactNode;
}

function TagClear(props: TagClearProps) {
  return <StyledTagClear {...props} />;
}

export default TagClear;
