/** @format */

import { styles } from '@common/application/styles/global';
// import styled from '@emotion/styled';
import styled from '@emotion/styled';
import { PrefixedInputProps } from '../index';

export const StyledPrefixedInput = styled.div<PrefixedInputProps>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;

  :focus {
    .iconWrapper {
      border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.hover.borderColor)};
    }
  }
  :focus-within {
    .iconWrapper {
      border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.hover.borderColor)};
      border-right-color: ${(props) => (props.postfix ? '' : styles.borderColor)};
      border-left-color: ${(props) => (props.postfix ? styles.borderColor : '')};
      :hover,
      :active,
      :focus {
        border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.hover.borderColor)};
        border-right-color: ${(props) => (props.postfix ? '' : styles.borderColor)};
        border-left-color: ${(props) => (props.postfix ? styles.borderColor : '')};
      }
    }
  }

  :hover {
    .iconWrapper {
      border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.hover.borderColor)};
      border-right-color: ${(props) => (props.postfix ? '' : styles.borderColor)};
      border-left-color: ${(props) => (props.postfix ? styles.borderColor : '')};
    }
    input {
      border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.hover.borderColor)} !important;
    }
  }

  .iconWrapper {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-left: 1px solid ${(props) => (props.postfix ? styles.borderColor : 'none')};
    border-right: 1px solid ${(props) => (props.postfix ? 'none' : styles.borderColor)};
    //border-radius: ${(props) => (props.postfix ? ' 0 4px 4px 0' : '4px 0 0 4px')};
    position: absolute;
    top: 1px;
    right: ${(props) => props.postfix && '0'};
    left: ${(props) => !props.postfix && '0'};
    bottom: 1px;
    z-index: 9;

    :hover,
    :active,
    :focus {
      input {
        border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.hover.borderColor)} !important;
      }
    }
  }

  input {
    padding-left: ${(props) => !props.postfix && '33px !important'};
    padding-right: ${(props) => props.postfix && '33px !important'};
  }
`;
