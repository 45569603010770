/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

const StyledInner = styled.div`
  box-sizing: border-box;
  background-color: ${cssVars.white};
  background-clip: padding-box;
  border-radius: 4px;
  //padding: 5px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  > h4 {
    text-align: center;
  }
`;

export default StyledInner;
