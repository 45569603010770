/** @format */

import styled from '@emotion/styled';

const StyledNavigationPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 0.8rem !important; // Unfortunately next/Image uses style to set margin (and others). Idiots...
  }

  > *:last-child {
    margin-right: 0;
  }
`;

export default StyledNavigationPanel;
