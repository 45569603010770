/** @format */

import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledCheckboxIcon = styled(FontAwesomeIcon)`
  margin: 0;
  padding: 0;
  border: none;
`;
