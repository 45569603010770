/** @format */

import ContentWrapper from '@atoms/ContentWrapper';
import ErrorDisplay from '@templates/Error/ErrorDisplay';
import { StyledErrorPage } from '@templates/Error/styled';
import React, { ReactNode } from 'react';

export interface ErrorPageProps {
  titleErrorMessage: ReactNode | string;
  errorCode: string;
}

function ErrorPage(props: ErrorPageProps) {
  return (
    <ContentWrapper layoutId={props.errorCode}>
      <StyledErrorPage>
        <ErrorDisplay {...props} />
      </StyledErrorPage>
    </ContentWrapper>
  );
}

export default ErrorPage;
