/** @format */

import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { UrlObject } from 'url';
import { StyledLinkUnderline, StyledText, StyledTextLink, TextProps } from './styled';

function Text(props: TextProps) {
  return (
    <StyledText css={props.className} onClick={props.onClick} secondary={props.secondary} link={props.link}>
      {props.children}
    </StyledText>
  );
}

Text.Plain = (props: TextProps) => {
  return <span>{props.children}</span>;
};

// @ts-ignore TODO: Fix
Text.Plain.displayName = 'Plain';

interface LinkProps extends TextProps {
  href?: string | UrlObject;
  plain?: boolean;
  disabled?: boolean;
  nodecoration?: boolean;
  external?: boolean;
  active?: boolean;
  download?: string;
}

Text.Link = (props: LinkProps) => {
  if (props.download) {
    return (
      <a
        download={props.download}
        css={css`
          text-decoration: none;
        `}
        href={props.href.toString()}
      >
        <StyledTextLink active={props.active} onClick={props.onClick} link css={props.className}>
          <div
            css={css`
              display: flex;
            `}
          >
            <FontAwesomeIcon
              css={css`
                margin-right: 8px;
              `}
              icon={['far', 'download']}
            />
            {props.children}
          </div>
          <StyledLinkUnderline link />
        </StyledTextLink>
      </a>
    );
  }
  if (props.nodecoration) {
    if (props.href && !props.disabled) {
      if (props.plain) {
        return (
          <Link href={props.href} passHref>
            <span onClick={props.onClick} css={props.className}>
              {props.children}
            </span>
          </Link>
        );
      }
      return (
        <Link href={props.href} passHref>
          <StyledTextLink active={props.active} onClick={props.onClick} link css={props.className}>
            {props.children}
          </StyledTextLink>
        </Link>
      );
    }
    if (props.plain) {
      return (
        <span onClick={props.onClick} css={props.className}>
          {props.children}
        </span>
      );
    }
    return (
      <StyledTextLink active={props.active} onClick={props.onClick} link css={props.className}>
        {props.children}
      </StyledTextLink>
    );
  }
  if (props.href && !props.disabled) {
    if (props.external) {
      if (props.plain) {
        return (
          <a
            download={props.download}
            css={css`
              text-decoration: none;
            `}
            href={props.href.toString()}
          >
            <span onClick={props.onClick} css={props.className}>
              <FontAwesomeIcon
                css={css`
                  margin-right: 8px;
                `}
                icon={['far', 'link']}
              />
              {props.children}
            </span>
          </a>
        );
      }
      return (
        <a
          download={props.download}
          css={css`
            text-decoration: none;
          `}
          href={props.href.toString()}
        >
          <StyledTextLink active={props.active} onClick={props.onClick} link css={props.className}>
            <FontAwesomeIcon
              css={css`
                margin-right: 8px;
              `}
              icon={['far', 'link']}
            />
            {props.children}
            <StyledLinkUnderline link />
          </StyledTextLink>
        </a>
      );
    }
    if (props.plain) {
      return (
        <Link href={props.href} passHref>
          <span onClick={props.onClick} css={props.className}>
            {props.children}
          </span>
        </Link>
      );
    }
    return (
      <Link href={props.href} passHref>
        <StyledTextLink active={props.active} onClick={props.onClick} link css={props.className}>
          {props.children}
          <StyledLinkUnderline link />
        </StyledTextLink>
      </Link>
    );
  }
  if (props.plain) {
    return (
      <span onClick={props.onClick} css={props.className}>
        {props.children}
      </span>
    );
  }
  return (
    <StyledTextLink active={props.active} onClick={props.onClick} link css={props.className}>
      {props.children}
      <StyledLinkUnderline link />
    </StyledTextLink>
  );
};

// @ts-ignore TODO: Fix
Text.Link.displayName = 'Link';

interface TimeProps {
  dateTime: Date | string;
}

function Time(props: TimeProps) {
  const intl = useIntl();

  return (
    <span title={intl.formatDate(props.dateTime, { dateStyle: 'short', timeStyle: 'full', timeZone: 'UTC' })}>
      <FormattedDate dateStyle="short" timeStyle="short" value={props.dateTime} timeZone="UTC" />
    </span>
  );
}

Text.Time = Time;

export default Text;
