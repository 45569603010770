/** @format */

import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export interface StyledContentWrapperProps {
  unpadded?: boolean;
}

// TODO: find better fix for this stupid hack to stop emotion passing a bool prop (unpadded) down to DOM
const StyledContentWrapper = styled(({ unpadded, children, ...props }) => <motion.section {...props}>{children}</motion.section>)<StyledContentWrapperProps>`
  width: 100%;
  height: 100%;
  padding: ${({ unpadded }) => (unpadded ? '' : '16px')};
  box-sizing: border-box;
`;
export default StyledContentWrapper;
