/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

const StyledErrorDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > h2 {
    color: ${cssVars.darkGrey};
  }
`;

export default StyledErrorDisplay;
