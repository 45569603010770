/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';

interface StyledTagInputWrapperProps {
  focussed: boolean;
}

const StyledTagInputWrapper = styled.div<StyledTagInputWrapperProps>`
  border: 1px solid ${(props) => (props.focussed ? styles.active.borderColor : styles.borderColor)};
  border-radius: 4px;
  background-color: ${styles.backgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: text;
  color: ${styles.color};
  font-family: 'Proxima Nova', sans-serif;
  overflow: hidden;

  > * {
    margin-right: 0.3rem;
  }

  > *:last-child {
    margin-right: 0;
  }

  :hover {
    border-color: ${styles.hover.borderColor};
  }
`;

export default StyledTagInputWrapper;
