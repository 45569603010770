/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { AriaPopoverProps } from '@react-aria/overlays';
import { DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import { useRouter } from 'next/router';
import * as React from 'react';
import type { OverlayTriggerState } from 'react-stately';

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  popoverRef?: React.RefObject<HTMLDivElement>;
  minWidth?: number;
  arrowed?: boolean;
  offset?: number;
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-top: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: fit-content;
  background: white;

  .arrow {
    position: absolute;
    fill: white;
    stroke: ${cssVars.lightGrey};
    stroke-width: 1px;
    width: 12px;
    height: 12px;
  }

  .arrow[data-placement='top'] {
    top: 100%;
    transform: translateX(-50%);
  }

  .arrow[data-placement='bottom'] {
    bottom: 100%;
    transform: translateX(-50%) rotate(180deg);
  }

  .arrow[data-placement='left'] {
    left: 100%;
    transform: translateY(-50%) rotate(-90deg);
  }

  .arrow[data-placement='right'] {
    right: 100%;
    transform: translateY(-50%) rotate(90deg);
  }
`;

function Popover({ offset = 8, ...props }: PopoverProps) {
  const router = useRouter();
  let ref = React.useRef<HTMLDivElement>(null);
  let { popoverRef = ref, state, children, isNonModal } = props;

  let { popoverProps, underlayProps, arrowProps, placement } = usePopover(
    {
      ...props,
      offset,
      popoverRef,
    },
    state,
  );

  // Close any popovers when the route changes
  router.events.on('routeChangeStart', () => {
    state.close();
  });

  return (
    <Overlay {...props}>
      {<div {...underlayProps} style={{ position: 'fixed', inset: 0 }} />}
      <Wrapper
        {...popoverProps}
        ref={popoverRef}
        css={css`
          min-width: ${props.minWidth}px;
        `}
      >
        {props.arrowed && (
          <svg {...arrowProps} data-placement={placement} className={'arrow'}>
            <path d="M0 0,L6 6,L12 0" />
          </svg>
        )}
        {<DismissButton onDismiss={state.close} />}
        {children}
        <DismissButton onDismiss={state.close} />
      </Wrapper>
    </Overlay>
  );
}

export default Popover;
