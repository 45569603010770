/** @format */

import axios from 'axios';

export class Currency {
  name: string;
  iso4217: string;
  iconName: string;

  constructor(iso4217, iconName, name) {
    this.iso4217 = iso4217;
    this.iconName = iconName;
    this.name = name;
  }

  //fetch

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Currency; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/currencies`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts ?? { deleted: false }),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static get(id: number): Promise<{ message: string; paginated: boolean; payload: Currency }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/currencies/${id}`).then((res) => ({ ...res.data }));
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/currencies/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/currencies/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static update(updatedCurrency: { name: string; companyId: number; logo: string }, id: number): Promise<Record<string, unknown>> {
    return axios.patch(`${window.location.protocol}//${window.location.host}/api/v1/currencies/${id}`, { payload: updatedCurrency }).then((res) => ({ ...res.data }));
  }

  static getAddTemplate(): Promise<Record<string, unknown>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/templates/currencies/add`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  add(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/currencies`, {
        payload: {
          name: this.name,
          iconName: this.iconName,
          iso4217: this.iso4217,
        },
      })
      .then((res) => ({ ...res.data }));
  }
}
