/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledReactNodeInput = styled.div`
  border: 1px solid ${styles.borderColor};
  border-radius: 4px;
  padding: 8px;
  background-color: ${cssVars.offWhite};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: text;
  color: ${cssVars.silverGrey};
  font-weight: normal;
  font-size: 0.84rem;

  > * {
    margin-right: 0.3rem;
  }

  > *:last-child {
    margin-right: 0;
  }
`;
