/** @format */

import Button from '@atoms/Button';
import useThrottledCallback from '@common/application/hooks/useThrottledCallback';
import { ErrorMail } from '@common/model/apiClient/ErrorMail';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

const StyledErrorFallback = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

interface IErrorProps {
  error: any;

  reset(): void;

  markSent(): void;

  isSent: boolean;
}

function ErrorFallback(props: IErrorProps) {
  const [mailError, setMailError] = useState(false);
  const [mailSuccess, setMailSuccess] = useState(false);

  const errorReport = useMutation(
    ['error_mail'],
    () => {
      if (mailSuccess) return null;
      return new ErrorMail(props.error.error, props.error.errorInfo).send();
    },
    {
      onSuccess: (_d) => {
        props.markSent();
        setMailSuccess(true);
      },
      onError: (_e) => {
        setMailError(true);
      },
    },
  );

  const reportError = useThrottledCallback(() => errorReport.mutate(), [errorReport.mutate], 2000);

  useEffect(() => {
    if (!props.error.error || errorReport.isLoading || errorReport.isSuccess || props.isSent) return;
    reportError();
  }, [props.error, mailSuccess, errorReport, props.isSent, reportError]);

  return (
    <StyledErrorFallback>
      <h2>
        <FormattedMessage defaultMessage="Something went wrong" description={'Notification that an error occurred'} />
      </h2>
      <h4>
        <FormattedMessage
          defaultMessage="You can try to reset the component by clicking this button. If that does not work then please try refreshing the page"
          description={'Label for button to refresh a component'}
        />
      </h4>
      <Button onClick={props.reset}>
        <FormattedMessage defaultMessage="Reload" description={'Restart/refresh a section of the page'} />
      </Button>
      {!mailError && !mailSuccess && (
        <FormattedMessage defaultMessage="A report of this error is being sent to the development team" description={'Message notifying user that an error report is being sent'} />
      )}
      {!mailError && mailSuccess && (
        <FormattedMessage defaultMessage="A report of this error was successfully sent" description={'Message notifying user that an error report has been sent'} />
      )}
    </StyledErrorFallback>
  );
}

export default ErrorFallback;
