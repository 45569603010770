/** @format */

import { DialoguesProvider } from '@atoms/Dialogue';
import ErrorBoundary from '@atoms/ErrorBoundary/ErrorBoundary';
import { GlobalStyles } from '@atoms/GlobalStyles';
import { NotificationsProvider } from '@atoms/Notification';
import en_GB from '@common/application/locales/compiled/en-GB.json'; // British English
import faIcons from '@common/model/fontawesome/faIcons';
import { css } from '@emotion/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { HeimdallAxiosInterceptor } from '@heimdall/axios';
import { HeimdallProvider } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import Page from '@quarks/Page';
import App from '@templates/App';
import axios from 'axios';
import { NextPage } from 'next';
import { Session } from 'next-auth';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import process from 'process';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import FourOhThree from './403';

library.add(...faIcons); // https://fontawesome.com/v6.0/docs/web/use-with/react/add-icons

interface InitialProps {
  cookies: unknown;
}

const queryClient = new QueryClient();

function SafeHydrate({ children }) {
  return <div suppressHydrationWarning>{typeof window === 'undefined' ? null : children}</div>;
}

interface TrackupProps extends AppProps {
  pageProps: {
    session: Session<BaseRealmRole, BaseRole>;
  };
}

const TrackUp: NextPage<TrackupProps & InitialProps> = ({ cookies, ...appProps }) => {
  const { pageProps } = appProps;
  const { locale, pathname } = useRouter();
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState();
  const router = useRouter();

  useEffect(() => {
    return HeimdallAxiosInterceptor.install(false);
  }, []);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        // Invalidate notification queries after each request, a simple alternative to polling or websockets
        // TODO - just set a refetch interval of maybe 1 min on the useQuery instead?
        if (response.request.responseURL.includes('notifications') || response.request.responseURL.includes('companies/totals')) return response;
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('user_notification_query') });
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('realm_banner_notifications') });
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('get_company_totals') });

        return response;
      },
      function (error) {
        switch (error.response.status) {
          case 401:
            // signOut({ callbackUrl: `${location.protocol}//${location.host}/auth/signedout` });
            break;
          default:
            // console.log(error.response);
            break;
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    import(`common/application/locales/compiled/${locale}.json`).then((res) => setMessages(res ?? en_GB));
  }, [locale]);

  // Need to suppress as ts typing was done by a moron
  // noinspection RequiredAttributes
  return (
    <SafeHydrate>
      <GlobalStyles />
      <HeimdallProvider refetchOnWindowFocus={true} refetchInterval={Number(process.env.NEXT_PUBLIC_MAX_ACCESS_TOKEN_AGE ?? '0') / 2 - 1} session={pageProps.session}>
        <IntlProvider messages={messages} locale={locale} defaultLocale="en-GB">
          <QueryClientProvider client={queryClient}>
            {/*<DeveloperTools queryTools>*/}
            <DialoguesProvider>
              <NotificationsProvider>
                <ErrorBoundary>
                  {error === 'onInitError' ? (
                    <Page
                      css={css`
                        height: 100vh;
                        width: 100vw;
                        display: flex;
                        flex-direction: column;
                      `}
                    >
                      <FourOhThree />
                    </Page>
                  ) : pathname.startsWith('/auth') || pathname.startsWith('/api') ? (
                    <appProps.Component {...appProps.pageProps} />
                  ) : (
                    <App {...appProps} />
                  )}
                </ErrorBoundary>
              </NotificationsProvider>
            </DialoguesProvider>
            {/*</DeveloperTools>*/}
          </QueryClientProvider>
        </IntlProvider>
      </HeimdallProvider>
    </SafeHydrate>
  );
};

// noinspection JSUnusedGlobalSymbols
export default TrackUp;
