/** @format */

import React, { ReactNode } from 'react';
import { StyledHeaderSubtext } from './styled';

interface HeaderSubtextProps {
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;

  children: ReactNode;
}

function HeaderSubtext(props: HeaderSubtextProps) {
  return <StyledHeaderSubtext {...props} />;
}

export default HeaderSubtext;
