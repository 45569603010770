/** @format */

const featureFlags = {
  //Hardcoded values! TODO: convert to unleash
  resources: false,
  globalsearch: false,
  'table-multiselect': false,
  'admin-resources': false,
  'admin-searchconfiguration': false,
  'admin-dataexports': true,
  wizard: false,
};

export default featureFlags;
