/** @format */

import styled from '@emotion/styled';
import { getColor, StyledTextProps } from './StyledText';

export const StyledLinkUnderline = styled.div<StyledTextProps>`
  border-bottom: 1px solid ${getColor};
  opacity: 0.8;
  width: 0;
  transition: width 80ms ease-in-out;
`;
