/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';

export const StyledCheckbox = styled.div`
  border: 1px solid ${styles.borderColor};
  border-radius: 4px;
  background-color: ${styles.backgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  cursor: pointer;
  color: ${styles.color};

  :hover {
    border-color: ${styles.hover.borderColor};

    > svg {
      color: ${styles.hover.borderColor};
    }
  }

  :focus {
    border-color: ${styles.active.borderColor};
    outline: none;
  }
`;
