/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledAutocompleteList = styled.div`
  margin-top: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: ${cssVars.white};
  border-radius: 3px;
  position: absolute;
  user-select: none;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
`;
