/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';

export const StyledSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: ${styles.color};
  cursor: pointer;
  height: 100%;
  justify-content: center;

  :hover {
    color: ${styles.color};
  }

  > svg {
    padding: 0 8px;
    height: 12px;
  }

  > svg:hover {
    color: ${styles.hover.borderColor};
  }
`;
