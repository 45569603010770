/** @format */

import styled from '@emotion/styled';

const StyledMenuContent = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export default StyledMenuContent;
