/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

const StyledMenuHeader = styled.div`
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${cssVars.lightGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
`;

export default StyledMenuHeader;
