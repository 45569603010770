/** @format */

import axios from 'axios';

export class ErrorMail {
  errorMessage: string;
  errorStack: string;
  errorInfo: object;
  timestamp: object;

  constructor(error: { message: string; stack: string }, errorInfo: object) {
    this.errorMessage = error.message;
    this.errorStack = error.stack;
    this.errorInfo = errorInfo;
    this.timestamp = new Date();
  }

  send(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/errormail`, {
        payload: this,
      })
      .then((res) => ({ ...res.data }));
  }
}
