/** @format */

import { TagType } from '@common/application/types';
import React, { ReactNode } from 'react';
import { StyledTag } from './styled';

//</editor-fold>

//<editor-fold desc="Component">
export interface TagProps {
  children?: ReactNode;
  tagType?: TagType;
}

export function Tag(props: TagProps) {
  return <StyledTag tagType={props.tagType ?? TagType.PRIMARY}>{props.children}</StyledTag>;
}

Tag.defaultProps = {};

export default Tag;
//</editor-fold>
