/** @format */

import styled from '@emotion/styled';

interface MenuBodyProps {
  onClick?(): void;
}

const StyledMenuBody = styled.div<MenuBodyProps>`
  padding: 16px;
  color: #3c91ff;
`;

export default StyledMenuBody;
