/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { BannerNotification } from '@common/model/apiClient/BannerNotification';
import styled from '@emotion/styled';
import Carousel from '@organisms/Carousel';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

interface BannerNotificationProps {}

const StyledNotificationBanner = styled.div`
  background: #e2d4b7;
  //background: #ccad8f;
  border-bottom: 1px solid ${cssVars.lightGrey};
  width: 100%;
  height: 64px;
  user-select: none;
  overflow: hidden;
`;

const NotificationWrapper = styled.span`
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  > span {
    color: ${cssVars.red};
    display: block;
    margin-left: 8px;
    font-size: 0.9rem;
  }
`;

function NotificationBanner(props: BannerNotificationProps) {
  const intl = useIntl();
  const [notifications, setNotifications] = useState([]);

  const checkIfActiveDate = (notification: { startDate: Date; endDate: Date }) => {
    const now = new Date().getTime();
    const start = new Date(notification.startDate).getTime();
    const end = new Date(notification.endDate).getTime();
    return start <= now && end >= now;
  };

  useQuery(['realm_banner_notifications'], () => BannerNotification.getForRealm(), {
    onSuccess: (d) => setNotifications(d.data.payload.filter((n) => checkIfActiveDate(n))),
  });

  const now = new Date();
  // if(now < props.startDate || now > props.endDate){
  //   return null;
  // }
  if (!notifications.length) {
    return null;
  }

  return (
    <StyledNotificationBanner>
      <Carousel
        interval={5000}
        carouselId={'bannerNotifications'}
        elements={notifications.map((n) => ({
          id: n.id,
          element: (
            <NotificationWrapper>
              {n.message}
              {n.countdown && (
                <span>
                  <Countdown
                    date={n.endDate}
                    renderer={({ days, hours, seconds, minutes }) => {
                      const daysString = days ? `${days} ${intl.formatMessage({ defaultMessage: 'days' })}` : '';
                      const hoursString = `${hours} ${intl.formatMessage({ defaultMessage: 'hours' })}`;
                      const secondsString = `${seconds} ${intl.formatMessage({ defaultMessage: 'secs', description: 'abbreviated form of "seconds"' })}`;
                      const minutesString = `${minutes} ${intl.formatMessage({ defaultMessage: 'mins', description: 'abbreviated form of "minutes"' })}`;

                      return `${daysString} ${hoursString} ${minutesString} ${secondsString}`;
                    }}
                  />
                </span>
              )}
            </NotificationWrapper>
          ),
        }))}
      />
    </StyledNotificationBanner>
  );
}

export default NotificationBanner;
