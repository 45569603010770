/** @format */

import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import React, { useEffect, useState } from 'react';

export function useIsAdmin() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isAdminChecked, setIsAdminChecked] = useState(false);
  const session = useSecureSession<BaseRealmRole, BaseRole>();

  useEffect(() => {
    if (!session?.data) {
      return;
    }
    if (session.impersonatedRole) {
      setIsAdmin(session?.data?.user?.roles?.includes?.('Administrator') && (session.impersonatedRole === 'Administrator' || session.impersonatedRole === 'Super Administrator'));
      setIsSuperAdmin(session?.data?.user?.roles?.includes?.('Super Administrator') && session.impersonatedRole === 'Super Administrator');
      setIsSuperUser(session?.data?.user?.roles?.includes?.('Super User') && session.impersonatedRole === 'Super User');
      setIsUser(session?.data?.user?.roles?.includes?.('User') && session.impersonatedRole === 'User');
      setIsAdminChecked(true);
    } else {
      setIsAdmin(session?.data?.user?.roles?.includes?.('Administrator'));
      setIsSuperAdmin(session?.data?.user?.roles?.includes?.('Super Administrator'));
      setIsSuperUser(session?.data?.user?.roles?.includes?.('Super User'));
      setIsUser(session?.data?.user?.roles?.includes?.('User') && session.impersonatedRole === 'User');
      setIsAdminChecked(true);
    }
  }, [session]);

  let highestRole = null;
  if (isAdminChecked) {
    if (isSuperAdmin) {
      highestRole = 'Super Administrator';
    } else if (isAdmin) {
      highestRole = 'Administrator';
    } else if (isSuperUser) {
      highestRole = 'Super User';
    } else if (isUser) {
      highestRole = 'User';
    }
  }

  return { isAdmin, isAdminChecked, isSuperAdmin, isSuperUser, isUser, highestRole };
}
