/** @format */

import FlexBox from '@atoms/FlexBox';
import HeaderSubtext from '@atoms/HeaderSubtext';
import Select from '@atoms/Select';
import Text from '@atoms/Text';
import useInterval from '@common/application/hooks/useInterval/useInveral';
import useLocales from '@common/application/hooks/useLocales/useLocales';
import useStrings from '@common/application/hooks/useStrings/useStrings';
import { css } from '@emotion/react';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import CurrencySelect from '@molecules/CurrencySelect';
import HeaderText from '@quarks/HeaderText';
import Inner from '@quarks/Inner';
import MenuBody from '@quarks/MenuBody';
import MenuContent from '@quarks/MenuContent';
import MenuHeader from '@quarks/MenuHeader';
import UserIconBig from '@quarks/UserIconBig';
import LocaleSelect from '@templates/App/LocaleSelect';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Item } from 'react-stately';

const roles = {
  '': -1,
  User: 0,
  'Super User': 1,
  Administrator: 2,
  'Super Administrator': 3,
};

interface UserMenuProps {}

export function UserMenu(props) {
  const session = useSecureSession<BaseRealmRole, BaseRole>();
  const [roleSelectorActive, setRoleSelectorActive] = useState(false);
  const [sessionTimeLeft, setSessionTimeLeft] = useState<null | number>(0);
  const { localesData, countryAlphaCodes } = useLocales();
  const { roleStrings } = useStrings();
  const router = useRouter();
  const intl = useIntl();

  function formatTime(time: number) {
    const days = Math.floor(time / 86400000);
    const _time = time % 86400000;
    const hours = Math.floor(_time / 3600000);
    const __time = time % 3600000;
    const minutes = Math.floor(__time / 60000);
    const seconds = Math.floor((__time % 60000) / 1000);

    const daysString = days ? `${days} ${intl.formatMessage({ defaultMessage: 'days' })}` : '';
    const hoursString = `${hours} ${intl.formatMessage({ defaultMessage: 'hours' })}`;
    const secondsString = `${seconds} ${intl.formatMessage({ defaultMessage: 'secs', description: 'abbreviated form of "seconds"' })}`;
    const minutesString = `${minutes} ${intl.formatMessage({ defaultMessage: 'mins', description: 'abbreviated form of "minutes"' })}`;

    return time !== null
      ? time === 0
        ? ''
        : `${daysString} ${hoursString} ${minutesString} ${secondsString}`
      : intl.formatMessage({ defaultMessage: 'Session Expired', description: 'Log in has expired and user must log in again' });
  }

  useInterval(() => {
    const now = new Date();
    const expires = new Date(session.data.session_expires_at * 1000);
    const ttl = Math.max(0, expires.valueOf() - now.valueOf());
    setSessionTimeLeft(ttl ? ttl : null);
  }, 1000);

  const availableRoles = useMemo(() => session?.data?.user?.roles, [session?.data?.user?.roles]);

  const highestAvailableRole = useMemo(() => availableRoles?.reduce((prevRole, role) => (roles[prevRole] < roles[role] ? role : prevRole), '') as BaseRole, [availableRoles]);

  return (
    <MenuContent>
      <Inner>
        <MenuHeader>
          <UserIconBig>
            {session.status === 'authenticated' ? session?.data?.user?.given_name?.substring(0, 1) + session?.data?.user?.family_name?.substring(0, 1) : ''}
          </UserIconBig>
          <HeaderText>
            <span
              css={css`
                text-align: start;
              `}
            >
              {session?.data?.user?.name}
            </span>
            <HeaderSubtext
              css={css`
                text-align: start;
              `}
            >
              {session?.data?.user?.username}@{window.location.host.split('.')[0]}
            </HeaderSubtext>
            <HeaderSubtext
              css={css`
                cursor: pointer;
                display: flex;
                justify-content: flex-start;
              `}
              onClick={() => {
                if (roleSelectorActive) {
                  return;
                }
                session.setImpersonatedRole((role) => (!role ? highestAvailableRole : role) as BaseRole);
                setRoleSelectorActive(true);
              }}
            >
              {roleSelectorActive ? (
                <Select
                  selectedKey={session.impersonatedRole ? session.impersonatedRole : highestAvailableRole}
                  defaultOpen
                  onSelectionChange={(role) => {
                    session.setImpersonatedRole(role as BaseRole);
                    setRoleSelectorActive(false);
                  }}
                  onBlur={() => {
                    setRoleSelectorActive(false);
                  }}
                >
                  {availableRoles.map((role) => (
                    <Item key={role}>{roleStrings[role]}</Item>
                  ))}
                </Select>
              ) : !session.impersonatedRole ? (
                roleStrings[highestAvailableRole]
              ) : (
                roleStrings[session.impersonatedRole]
              )}
            </HeaderSubtext>
          </HeaderText>
        </MenuHeader>
        <MenuHeader>
          <Text
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
            `}
          >
            <FormattedMessage defaultMessage="Session Expires In: " description="User menu session expires in timer label" /> {formatTime(sessionTimeLeft)}
          </Text>
        </MenuHeader>
        <MenuHeader>
          <FlexBox justifyContent={'space-around'} width={'100%'}>
            <LocaleSelect countryAlphaCodes={countryAlphaCodes} localesData={localesData} />
            <CurrencySelect />
          </FlexBox>
        </MenuHeader>
        <MenuBody>
          <span
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Text
              onClick={() => {
                router.push('/settings');
                props.onClose();
              }}
              link
            >
              <FormattedMessage defaultMessage="Settings" description="Link to the settings/preferences page" />
            </Text>
            <Text onClick={() => signOut({ callbackUrl: `/api/auth/single-sign-out` })} link>
              <FormattedMessage defaultMessage="Sign Out" description="User menu sign out link text" />
            </Text>
          </span>
        </MenuBody>
      </Inner>
    </MenuContent>
  );
}
