/** @format */

import styled from '@emotion/styled';

const StyledMain = styled.main`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export default StyledMain;
