/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import NotificationCard from '@atoms/NotificationCard';
import useStrings from '@common/application/hooks/useStrings/useStrings';
import { Notification } from '@common/model/apiClient/Notification';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import StyledInner from '@quarks/Inner';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQueryClient } from 'react-query';

interface NotifcationMenuProps {
  notifications: Array<Notification>;
}

const StyledNotificationMenu = styled.div`
  //max-height: 70vh;

  h4 {
    margin: 0;
    padding: 8px 16px;
    border-bottom: 1px solid ${cssVars.lightGrey};
    //width: 95%;
    font-size: 1.1rem;
    text-align: left;
  }
`;

const NotificationsList = styled(motion.div)`
  max-height: 50vh;
  //overflow-y: scroll;
`;

const NotificationActions = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${cssVars.lightGrey};

  span {
    cursor: pointer;
    color: ${cssVars.blue};
  }
`;

const NotificationMenu = (props: NotifcationMenuProps) => {
  const { notificationStrings } = useStrings();
  const session = useSecureSession<BaseRealmRole, BaseRole>();
  const queryClient = useQueryClient();

  const deleteAll = useMutation(['delete_all_notifications', session?.data?.user?.id], () => Notification.deleteAllForUser(session?.data?.user?.id), {
    onSuccess: () => queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('user_notification_query') }),
  });

  const markAllRead = useMutation(['mark_all_notifications_read', session?.data?.user?.id], () => Notification.markAllRead(session?.data?.user?.id), {
    onSuccess: () => queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('user_notification_query') }),
  });

  return (
    <StyledNotificationMenu>
      {/*<Arrow />*/}
      <StyledInner>
        <h4>{notificationStrings.notificationsHeader}</h4>
        {!!props.notifications?.length && (
          <AnimatePresence>
            <PerfectScrollbar>
              <NotificationsList key={'notificationList'} initial={{ height: 0 }} animate={{ height: 'auto' }} transition={{ delay: 0.06, duration: 0.25 }} exit={{ height: 0 }}>
                {props.notifications
                  .sort((a, b) => {
                    return (new Date(b.createdAt) as unknown as number) - (new Date(a.createdAt) as unknown as number);
                  })
                  .map((n) => (
                    <NotificationCard notification={n} key={n.id + 'notification'} />
                  ))}
              </NotificationsList>
            </PerfectScrollbar>
            <NotificationActions>
              <span onClick={() => markAllRead.mutate()}>{notificationStrings.markAllRead}</span>
              <span onClick={() => deleteAll.mutate()}>{notificationStrings.clearAll}</span>
            </NotificationActions>
          </AnimatePresence>
        )}
        {!props.notifications?.length && (
          <div
            css={css`
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 8px 16px;
            `}
          >
            {notificationStrings.noNotifications}
          </div>
        )}
      </StyledInner>
    </StyledNotificationMenu>
  );
};

export default NotificationMenu;
