/** @format */

import useSecureQuery from '@common/application/auth/useSecureQuery';
import { Locale } from '@common/model/apiClient/Locale';
import React, { useEffect, useState } from 'react';

function UseLocales() {
  const [countryAlphaCodes, setCountryAlphaCodes] = useState([]);
  const [localesData, setLocalesData] = useState([]);

  const localesQuery = useSecureQuery(['locales_with_currency_not_deleted'], () => Locale.list('', [], { musts: [{ exists: { field: 'defaultCurrencyId' } }] }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });

  useEffect(() => {
    if (!localesQuery.isSuccess) return;
    const uniqueCodes = new Set(localesQuery.data.payload?.map?.((locale) => locale._source.countryId) ?? []);
    setCountryAlphaCodes(Array.from(uniqueCodes));
    setLocalesData(
      localesQuery.data.payload?.map?.((locale) => {
        const l = locale._source;
        return {
          bcp47: l.bcp47,
          countryId: l.countryId,
          defaultCurrencyId: l?.defaultCurrencyId,
          description: l.description,
          microsoftLanguageCode: l.microsoftLanguageCode,
          nativeDescription: l.nativeDescription,
        };
      }) ?? [],
    );
  }, [localesQuery.data, localesQuery.isSuccess]);

  return { localesData, countryAlphaCodes };
}

export default UseLocales;
