/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';
import React from 'react';

interface NotificationBadgeProps {
  notificationCount: number;
}

const StyledNotificationBadge = styled.span`
  position: absolute;
  top: 0%;
  right: -20%;
  height: 17px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${cssVars.red};
  color: ${cssVars.white};
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
`;

function NotificationBadge(props: NotificationBadgeProps) {
  return <StyledNotificationBadge>{props.notificationCount < 100 ? props.notificationCount : '99+'}</StyledNotificationBadge>;
}

export default NotificationBadge;
