/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Text from '@atoms/Text';
import { css } from '@emotion/react';
import { ErrorPageProps } from '@templates/Error/ErrorPage';
import { StyledErrorDisplay } from '@templates/Error/styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function ErrorDisplay(props: ErrorPageProps) {
  return (
    <StyledErrorDisplay>
      <h1>{props.errorCode}</h1>
      <div
        css={css`
          height: 150px;
          border-left: solid 1px ${cssVars.lightGrey};
          margin: 0 16px;
        `}
      />
      <div>
        <h2>{props.titleErrorMessage}</h2>
        <Text.Link href="/">
          <FormattedMessage description={`400 error page link to go back to the home page.`} defaultMessage={'Go back home'} />
        </Text.Link>
        <br />
        <Text.Link href={`${process.env.NEXT_PUBLIC_REPORT_ERROR_URL}`} external>
          <FormattedMessage description={`400 error page link to report an issue`} defaultMessage={'Report an issue'} />
        </Text.Link>
      </div>
    </StyledErrorDisplay>
  );
}

export default ErrorDisplay;
