/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

const StyledTagClear = styled.span`
  cursor: pointer;
  margin-left: 4px;

  :hover {
    color: ${cssVars.red};
  }
`;

export default StyledTagClear;
