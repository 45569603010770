/** @format */

import { useSecureSession } from '@heimdall/react';
import ErrorPage from '@templates/Error/ErrorPage';
import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Page - Displays an error for HTTP 403 'Forbidden'
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function FourOhThree() {
  useSecureSession({ signInUnauthenticatedUsers: true, redirectToSignInPageOnError: true });

  return <ErrorPage errorCode={'403'} titleErrorMessage={<FormattedMessage description={`403 error page header.`} defaultMessage={'This site cannot be accessed.'} />} />;
}
