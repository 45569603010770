/** @format */

import FlexBox from '@atoms/FlexBox';
import { cssVars } from '@atoms/GlobalStyles';
import NavLink from '@atoms/NavLink';
import logo from '@common/application/assets/images/logo-cropped.png';
import useOnClickOutside from '@common/application/hooks/useOnClickOutside/src';
import Feature from '@common/prototypes/Feature';
import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import NotificationButton from '@organisms/NotificationButton';
import UserButton from '@templates/App/UserButton';
import { Squash as MenuButton } from 'hamburger-react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ILink {
  href: string;
  text: string | EmotionJSX.Element;
  active?: boolean;
  adminOnly?: boolean;
}
interface MobileNavProps {
  links: Array<ILink>;
}

const StyledMobileNav = styled.nav`
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.3rem 8px;
  align-items: center;
  box-sizing: border-box;
  border-bottom: solid 1px ${cssVars.lightGrey};
  background-color: ${cssVars.offWhite};
  user-select: none;
  position: relative;
  height: 45px;
`;

const NavDropdown = styled.div<{ open: boolean }>`
  position: absolute;
  top: 100%;
  height: ${(props) => (props.open ? 'calc(100vh - 45px)' : 0)};
  background: ${cssVars.offWhite};
  z-index: 999;
  left: 0;
  right: 0;
  transition: all 0.5s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding: ${(props) => (props.open ? '16px' : '0 16px')};
  font-size: 20px;
`;
function MobileNav(props: MobileNavProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const buttonRef = useRef();
  const router = useRouter();

  // TODO add useOnCLickOutside

  useOnClickOutside(
    ref,
    () => {
      setOpen(false);
    },
    { ignoredElements: [buttonRef.current] },
  );

  router.events.on('routeChangeStart', () => {
    setOpen(false);
  });

  return (
    <StyledMobileNav>
      <span ref={buttonRef}>
        <MenuButton toggled={open} toggle={setOpen} size={35} color={cssVars.grey} />
      </span>
      <Link passHref href={'/'}>
        <a>
          <Image
            title={'Home'}
            css={css`
              cursor: pointer;
            `}
            src={logo}
            width={64}
            height={32}
            alt="trackup"
          />
        </a>
      </Link>
      <FlexBox gap={'18px'}>
        <NotificationButton />
        <UserButton />
      </FlexBox>
      <NavDropdown open={open} ref={ref}>
        {props.links.map((link) => (
          <NavLink href={link.href} text={link.text} active={link.active} key={`navLink${link.href}`} />
        ))}
        {Feature('resource').render(<NavLink href={'/resources'} text={<FormattedMessage defaultMessage="Resources" description="Resources page link in the navigation bar" />} />)}
      </NavDropdown>
    </StyledMobileNav>
  );
}

export default MobileNav;
