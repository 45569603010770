/** @format */

import styled from '@emotion/styled';

export const StyledUserIcon = styled.div`
  margin: 0;
  padding: 0;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: blueviolet;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export default StyledUserIcon;
