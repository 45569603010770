/** @format */

import axios from 'axios';

export class Locale {
  bcp47: string;
  countryId: string;
  microsoftLanguageCode: string;
  description: string;
  defaultCurrencyId?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  nativeDescription?: string;

  constructor(bcp47: string, countryId: string, microsoftLanguageCode: string, description: string, defaultCurrencyId: string, nativeDescription: string) {
    this.bcp47 = bcp47;
    this.countryId = countryId;
    this.microsoftLanguageCode = microsoftLanguageCode;
    this.description = description;
    this.defaultCurrencyId = defaultCurrencyId;
    this.nativeDescription = nativeDescription;
  }

  static list(
    query: string,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Locale; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/locales`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res, data: undefined, ...res.data }));
    }
    throw new Error();
  }

  static get(id: string): Promise<{ message: string; paginated: boolean; payload: Locale }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/locales/${id}`).then((res) => ({ ...res.data }));
  }

  static delete(id: string): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/locales/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: string): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/locales/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static update(updatedLocale: { name: string; companyId: number; logo: string }, id: string): Promise<Record<string, unknown>> {
    return axios.patch(`${window.location.protocol}//${window.location.host}/api/v1/locales/${id}`, { payload: updatedLocale }).then((res) => ({ ...res.data }));
  }

  static getAddTemplate(): Promise<Record<string, unknown>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/templates/locales/add`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  add(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/locales`, {
        payload: {
          bcp47: this.bcp47,
          countryId: this.countryId,
          microsoftLanguageCode: this.microsoftLanguageCode,
          description: this.description,
          defaultCurrency: this.defaultCurrencyId,
        },
      })
      .then((res) => ({ ...res.data }));
  }
}
