/** @format */

import { useResize } from '@common/application/hooks/useResize/src';
import { useEffect, useState } from 'react';

const IS_SERVER = !(typeof window !== 'undefined' && window.document && window.document.createElement);

// determine if we are in fullscreen mode and why
// don't set any state in here as called on init too
// @ts-ignore
export function UseFullscreen(el) {
  if (el && el.current) {
    return Boolean(
      document.fullscreenElement === el.current ||
        // @ts-ignore
        document.mozFullScreenElement === el.current ||
        // @ts-ignore
        document.webkitFullscreenElement === el.current ||
        // @ts-ignore
        document.msFullscreenElement === el.current,
    );
  }

  // suppress for .fullscreen deprecation, to support other browsers
  // noinspection JSDeprecatedSymbols
  return Boolean(
    document.fullscreenElement ||
      // @ts-ignore
      document.mozFullScreenElement ||
      // @ts-ignore
      document.webkitFullscreenElement ||
      // @ts-ignore
      document.msFullscreenElement ||
      document.fullscreen ||
      // @ts-ignore
      document.mozFullScreen ||
      // @ts-ignore
      document.webkitIsFullScreen ||
      // @ts-ignore
      document.fullScreenMode,
  );
}

export function useFullScreen(options = {}) {
  // @ts-ignore
  const fsEl = options && options.element;
  const initialState = IS_SERVER ? false : UseFullscreen(fsEl);
  const [fullScreen, setFullScreen] = useState(initialState);

  // access various open fullscreen methods
  function openFullScreen() {
    const el = (fsEl && fsEl.current) || document.documentElement;

    if (el.requestFullscreen) return el.requestFullscreen();
    if (el.mozRequestFullScreen) return el.mozRequestFullScreen();
    if (el.webkitRequestFullscreen) return el.webkitRequestFullscreen();
    if (el.msRequestFullscreen) return el.msRequestFullscreen();
  }

  // access various exit fullscreen methods
  function closeFullScreen() {
    if (document.exitFullscreen) return document.exitFullscreen();
    // @ts-ignore
    if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
    // @ts-ignore
    if (document.webkitExitFullscreen) return document.webkitExitFullscreen();
    // @ts-ignore
    if (document.msExitFullscreen) return document.msExitFullscreen();
  }

  useEffect(() => {
    function handleChange() {
      setFullScreen(UseFullscreen(fsEl));
    }

    document.addEventListener('webkitfullscreenchange', handleChange, false);
    document.addEventListener('mozfullscreenchange', handleChange, false);
    document.addEventListener('msfullscreenchange', handleChange, false);
    document.addEventListener('MSFullscreenChange', handleChange, false); // IE11
    document.addEventListener('fullscreenchange', handleChange, false);

    return () => {
      document.removeEventListener('webkitfullscreenchange', handleChange);
      document.removeEventListener('mozfullscreenchange', handleChange);
      document.removeEventListener('msfullscreenchange', handleChange);
      document.removeEventListener('MSFullscreenChange', handleChange);
      document.removeEventListener('fullscreenchange', handleChange);
    };
    // @ts-ignore
  }, [options.element, fsEl]);

  return {
    fullScreen,
    open: openFullScreen,
    close: closeFullScreen,
    toggle: fullScreen ? closeFullScreen : openFullScreen,
  };
}

export function getSizeInfo() {
  if (IS_SERVER) return {};
  return {
    screenTop: window.screenTop,
    screenY: window.screenY,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  };
}

// @ts-ignore
export function isFullScreenSize(sizeInfo) {
  if (sizeInfo.screenWidth === sizeInfo.innerWidth && sizeInfo.screenHeight === sizeInfo.innerHeight) {
    return true;
  } else if (!sizeInfo.screenTop && !sizeInfo.screenY) {
    return true;
  }

  return false;
}

export function useFullScreenBrowser() {
  const size = useResize();
  const initialSizeInfo = getSizeInfo();

  const [fullScreen, setFullScreen] = useState(IS_SERVER ? false : isFullScreenSize(initialSizeInfo));
  const [sizeInfo, setSizeInfo] = useState(initialSizeInfo);

  useEffect(() => {
    const sizeInfo = getSizeInfo();
    setFullScreen(isFullScreenSize(sizeInfo));
    setSizeInfo(sizeInfo);
  }, [size.width, size.height]);

  return {
    fullScreen: fullScreen,
    info: sizeInfo,
  };
}
