/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledDialogueWrapper = styled.div`
  min-height: fit-content;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${cssVars.black}88;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;
