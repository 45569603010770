/** @format */

import React, { MouseEventHandler, ReactNode } from 'react';
import { StyledUserIcon } from './styled';

interface UserIconProps {
  // onClick: MouseEventHandler<HTMLDivElement>;

  children: ReactNode;
}

const UserIcon = React.forwardRef<HTMLDivElement, UserIconProps>((props, ref) => {
  return (
    <StyledUserIcon id="user-icon" ref={ref} {...props}>
      {props.children}
    </StyledUserIcon>
  );
});

UserIcon.displayName = 'UserIcon';

export default UserIcon;
