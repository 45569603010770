/** @format */

import axios from 'axios';

export class Author {
  id?: string;
  // noinspection JSUnusedGlobalSymbols
  createdTimestamp?: number;
  username?: string;
  enabled?: boolean;

  // noinspection JSUnusedGlobalSymbols
  emailVerified?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  access?: {
    manageGroupMembership?: boolean;
    view?: boolean;
    mapRoles?: boolean;
    impersonate?: boolean;
    manage?: boolean;
  };

  static list(search: string): Promise<{ payload: { data: Array<Author>; realm: string } }> {
    if (typeof window !== 'undefined') {
      return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/sso/authors?search=${search}`).then((res) => ({ ...res.data }));
    }
    throw new Error();
  }
}
