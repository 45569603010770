/** @format */

import { NOTIFICATION, useNotifications } from '@atoms/Notification';
import useStrings from '@common/application/hooks/useStrings/useStrings';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import React from 'react';

function useQueryFeedback() {
  const { notify } = useNotifications();
  const { feedbackStrings } = useStrings();

  const notifySuccess = () => {
    return notify({
      variant: NOTIFICATION.SUCCESS,
      message: <span>{feedbackStrings.success}</span>,
    });
  };

  const notifyFail = (e?: AxiosError<{ payload: any }>) => {
    const payload = e?.response?.data?.payload;
    const copyErrorReference = async (event) => {
      event.preventDefault();
      await navigator.clipboard.writeText(payload.errorId);
      notify({
        message: <span>Error reference copied to clipboard</span>,
      });
      console.log('copy reference');
    };
    return notify({
      message: (
        <span>
          {feedbackStrings.fail}
          {e && (
            <span
              css={css`
                font-size: 12px;
              `}
            >
              <p>Code: {e.response?.status}</p>
              <p>{e.response?.statusText}</p>
              {payload && (
                <>
                  {typeof e.response?.data?.payload === 'string' ? (
                    <p>{payload}</p>
                  ) : (
                    <div>
                      <p>{payload.message}</p>
                      {location.protocol === 'https:' ? (
                        <p
                          onClick={copyErrorReference}
                          css={css`
                            cursor: pointer;
                            display: flex;
                            gap: 4px;
                          `}
                        >
                          Reference:
                          <FontAwesomeIcon icon={['far', 'clipboard']} />
                          {payload.errorId}
                        </p>
                      ) : null}
                    </div>
                  )}
                </>
              )}
              {/*{e.response?.data?.payload && <p>{typeof e.response?.data?.payload === 'string' ? e.response?.data.payload : JSON.stringify(e.response.data.payload)}</p>}*/}
            </span>
          )}
        </span>
      ),
      indefinite: true,
    });
  };

  return { notifySuccess, notifyFail };
}

export default useQueryFeedback;
