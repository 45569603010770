/** @format */

import { Resource } from '@common/application/enums/Resource';
import { AuditClient } from '@common/model/apiClient';
import axios from 'axios';

const uploadFileToSignedUrl = async (signedUrl: string, file: File, record: Record<string, any>, resourceAccessor: string, resource: Resource) => {
  if (!signedUrl) return;
  const localAxios = axios.create();
  await localAxios.put(signedUrl, file, { headers: { 'Content-Type': 'application/octet-stream' } });
  await AuditClient.auditSignedUrlUpload(record, record[resourceAccessor], resource);
};

export default uploadFileToSignedUrl;
