/** @format */

import { CurrencyContext } from '@common/application/context/CurrencyProvider';
import React, { ReactElement, useContext } from 'react';
import { StyledPrefixedInput } from './styled';

export interface PrefixedInputProps {
  postfix?: boolean;
  icon?: ReactElement;
  children?: any;
  disabled?: boolean;
}

export function PrefixedInput(props: PrefixedInputProps) {
  const { selectedCurrencyIcon } = useContext(CurrencyContext);

  return (
    <StyledPrefixedInput {...props}>
      <span className="iconWrapper">
        {/*  @ts-ignore*/}
        {props.icon ?? selectedCurrencyIcon}
      </span>

      {props.children}
    </StyledPrefixedInput>
  );
}

export default PrefixedInput;
