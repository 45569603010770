/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StyledAutocompleteModalBody = styled.div`
  //display: grid;
  //grid-template-rows: auto minmax(0, 1fr);
  //gap: 12px;
  //height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  height: 80vh;
`;

export const StyledAutocompleteModalList = styled.ul`
  margin: 0;
  border: 1px solid ${cssVars.lightGrey};
  padding: 8px 6px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  height: calc(80vh - 85px);
  //height: 100%;
  //flex-basis: 100%;
  //overflow-y: auto;
  transition: height 0.3s ease;
  //box-shadow: ${cssVars.lightGrey}ee 0px -50px 36px -43px inset, ${cssVars.lightGrey}ee 0px 50px 36px -43px inset;
  // ::-webkit-scrollbar {
  //   width: 10px;
  // }
  //
  // /* Track */
  // ::-webkit-scrollbar-track {
  //   background: #f1f1f1;
  // }
  //
  // /* Handle */
  // ::-webkit-scrollbar-thumb {
  //   background: ${cssVars.silverGrey};
  // }
  //
  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: ${cssVars.blue};
  // }
`;

export const StyledAutocompleteModalListItem = styled(motion.li)`
  cursor: pointer;
  padding: 16px 4px;
  border-bottom: 1px solid ${cssVars.lightGrey};
  display: flex;
  align-items: center;
  user-select: none;
  flex-basis: auto;
  flex-grow: 0;
  :last-of-type:not(:first-of-type) {
    border-bottom: none;
  }
  :hover {
    background: ${cssVars.blue};
    color: ${cssVars.white};
  }
`;
