/** @format */

import styled from '@emotion/styled';
import { getColor, getCursor, getHoverColor, StyledTextProps } from './StyledText';

export const StyledTextLink = styled.div<StyledTextProps>`
  height: fit-content;
  width: fit-content;
  color: ${getColor};
  cursor: ${getCursor};
  position: relative;

  ${({ active }) => (active ? '> *:last-child { width: 100% }' : null)}

  :hover {
    color: ${getHoverColor};
  }

  :hover > *:last-child {
    width: 100%;
  }
`;
