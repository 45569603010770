/** @format */

import { TagType } from '@common/application/types';
//<editor-fold desc="styled Component">
import styled from '@emotion/styled';

interface StyledTagProps {
  tagType: TagType;
}

function getBorderColor(tagType: TagType) {
  switch (tagType) {
    case TagType.ERROR:
      return '#eb8f8f';
    case TagType.WARNING:
      return '#ebdf8f';
    case TagType.SUCCESS:
      return '#b7eb8f';
    case TagType.PRIMARY:
    default:
      return '#8fc8eb';
  }
}

function getBackgroundColor(tagType: TagType) {
  switch (tagType) {
    case TagType.ERROR:
      return '#ffeded';
    case TagType.WARNING:
      return '#fffded';
    case TagType.SUCCESS:
      return '#f6ffed';
    case TagType.PRIMARY:
    default:
      return '#edfcff';
  }
}

function getColor(tagType: TagType) {
  switch (tagType) {
    case TagType.ERROR:
      return '#c41a1a';
    case TagType.WARNING:
      return '#c4bc1a';
    case TagType.SUCCESS:
      return '#52c41a';
    case TagType.PRIMARY:
    default:
      return '#1aabc4';
  }
}

export const StyledTag = styled.span<StyledTagProps>`
  user-select: none;
  border: 1px solid ${({ tagType }) => getBorderColor(tagType)};
  border-radius: 3px;
  background-color: ${({ tagType }) => getBackgroundColor(tagType)};
  padding: 4px 8px;
  font-size: 8pt;
  color: ${({ tagType }) => getColor(tagType)};
`;

StyledTag.defaultProps = {};
