/** @format */

import styled from '@emotion/styled';
import { DIALOGUE } from '../index';

interface StyledDialogueProps {
  variant: DIALOGUE;
}

export const StyledDialogue = styled.div<StyledDialogueProps>`
  width: fit-content;
  position: sticky;
  top: 25vh;
  left: 10%;
  background: white;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  margin: 24px;
`;
