/** @format */

import styled from '@emotion/styled';

interface StyledTagContainerProps {
  tagCount?: number;
}

const StyledTagContainer = styled.div<StyledTagContainerProps>`
  display: ${({ tagCount }) => (tagCount === 0 ? 'none' : 'flex')};
  flex-direction: row;
  margin: 0.2rem;

  > * {
    margin-right: 8px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

export default StyledTagContainer;
