/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

const StyledHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22pt;
  line-height: 26pt;
  color: ${cssVars.grey};
  font-weight: 500;
`;

export default StyledHeaderText;
