/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  :hover {
    color: ${styles.hover.borderColor};
  }
`;
