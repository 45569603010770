/** @format */

import { Resource } from '@common/application/enums/Resource';
import axios from 'axios';

export class Audit {
  id: number;
  event: Record<string, unknown>;
  date: string;

  constructor(id: number, event: Record<string, unknown>, date: string) {
    this.id = id;
    this.event = event;
    this.date = date;
  }

  static list(
    query,
    sort,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Audit; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/audits`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static async auditSignedUrlUpload(record: Record<string, any>, path: string, resource: Resource) {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/audit/auditupload`, { record, path, resource });
  }

  static async getAuditForSignedUrlUpload(path: string) {}
}
