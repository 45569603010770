/** @format */
import flags from '@common/application/config/featureFlags.config';
import { css } from '@emotion/react';
import { ReactNode } from 'react';

function isDevelopment() {
  return process.env.NEXT_PUBLIC_TRACKUP_ENV === 'development'; // && process.env.DISABLE_NON_PRODUCTION_FEATURES === 'false';
}

function feature(flag, email?: string) {
  return {
    isEnabled: (_flag = flag) => isDevelopment() || flags[_flag],
    render: (element: ReactNode) =>
      isDevelopment() ? (
        flags[flag] ? (
          element
        ) : (
          <div
            css={css`
              border: solid 1px red;
            `}
          >
            {element}
          </div>
        )
      ) : flags[flag] ? (
        element
      ) : null,
    set: (value: boolean) => (flags[flag] = value),
  };
}

declare global {
  interface Window {
    setApplicationFlag(flag: string, value: boolean): void;
  }
}

if (typeof window !== 'undefined') {
  window.setApplicationFlag = (flag, value) => {
    feature(flag).set(value);
  };
}

export default feature;
