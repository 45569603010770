/** @format */

import { State } from '@atoms/Checkbox';
import { TableSettings, TSettings } from '@common/application/hooks/useDefaultSettings';
import { SettingsKey } from '@common/application/hooks/useUserSettings';

const updateLegacySettings = (key: SettingsKey, settings: Record<string, any>, defaultSettings: TSettings): TableSettings => {
  if (key === SettingsKey.TABLES) {
    let updatedSettings = {};
    for (const table in settings) {
      const setting = settings[table];
      const defaults = defaultSettings[table];
      // Hacky check to differentiate between old settings structure and new
      if (!('columns' in setting)) {
        const hiddenColumns = [];
        defaults.columns.forEach((column) => {
          if (setting[column] === State.FALSE) {
            hiddenColumns.push(column);
          }
        });

        updatedSettings[table] = { ...defaults, hiddenColumns };
      } else {
        updatedSettings[table] = setting;
      }
    }
    return updatedSettings as TableSettings;
  }

  return settings as TableSettings;
};

export default updateLegacySettings;
