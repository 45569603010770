/** @format */

import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import React, { useMemo } from 'react';
import { StyledCheckbox, StyledCheckboxIcon, StyledSwitch } from './styled';

export enum State {
  TRUE,
  FALSE,
  INDETERMINATE,
}

export interface CheckboxProps {
  value: State;
  label?: string | EmotionJSX.Element;

  onToggle?(value: State): void;
}

//</editor-fold>

export function Checkbox(props: CheckboxProps) {
  const icon = useMemo(() => {
    switch (props.value) {
      case State.TRUE:
        return 'fa-solid fa-check';
      case State.FALSE:
        return null;
      case State.INDETERMINATE:
      default:
        return 'fa-solid fa-minus';
    }
  }, [props.value]);

  function toggle() {
    switch (props.value) {
      case State.TRUE:
        props.onToggle(State.FALSE);
        break;
      case State.FALSE:
        props.onToggle(State.TRUE);
        break;
      case State.INDETERMINATE:

      default:
        props.onToggle(State.TRUE);
        break;
    }
  }

  if (props.label) {
    return (
      <label
        htmlFor=""
        css={css`
          display: flex;
          gap: 8px;
        `}
        onClick={toggle}
      >
        {/*// @ts-ignore*/}
        <StyledCheckbox>{icon ? <StyledCheckboxIcon size="xs" icon={icon} /> : null}</StyledCheckbox>
        {props.label}
      </label>
    );
  }
  // @ts-ignore
  return <StyledCheckbox onClick={toggle}>{icon ? <StyledCheckboxIcon size="xs" icon={icon} /> : null}</StyledCheckbox>;
}

function Switch(props: CheckboxProps) {
  function toggle() {
    switch (props.value) {
      case State.TRUE:
        props.onToggle(State.FALSE);
        break;
      case State.FALSE:
      case State.INDETERMINATE:
      default:
        props.onToggle(State.TRUE);
        break;
    }
  }

  return (
    <label
      css={css`
        display: flex;
      `}
      onClick={toggle}
    >
      <StyledSwitch
        css={
          props.label
            ? css`
                margin-right: 8px;
              `
            : null
        }
        state={props.value}
      />
      {props.label}
    </label>
  );
}

Checkbox.Switch = Switch;

// noinspection JSUnusedGlobalSymbols
export default Checkbox;
