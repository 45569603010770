/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';
import { State } from '../index';

interface StyledSwitchProps {
  state: State;
}

export const StyledSwitch = styled.div<StyledSwitchProps>`
  border: 1px solid ${styles.borderColor};
  border-radius: 9px;
  padding: 1px;
  background-color: ${styles.backgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 36px;
  height: 18px;
  box-sizing: border-box;
  cursor: pointer;
  color: ${styles.color};

  :after {
    content: '';
    //border: 1px solid ${styles.borderColor};
    background-color: ${({ state }) => (state === State.INDETERMINATE ? styles.borderColor : state === State.TRUE ? styles.hover.borderColor : styles.borderColor)};
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-left: ${({ state }) => (state === State.INDETERMINATE ? '9px' : state === State.TRUE ? '18px' : '0')};
    transition: margin-left 60ms linear, background-color 60ms linear;
  }

  :hover {
    border-color: ${styles.hover.borderColor};

    > svg {
      color: ${styles.hover.borderColor};
    }

    :after {
      background-color: ${styles.hover.borderColor};
    }
  }

  :focus {
    border-color: ${styles.active.borderColor};
    outline: none;
  }
`;
