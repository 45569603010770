/** @format */

import { useSecureSession } from '@heimdall/react';
import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

type UseSecureQuery =
  | (<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
      options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    ) => UseQueryResult<TData, TError>)
  | (<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
      queryKey: TQueryKey,
      options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey'>,
    ) => UseQueryResult<TData, TError>)
  | (<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
      queryKey: TQueryKey,
      queryFn: QueryFunction<TQueryFnData, TQueryKey>,
      options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
    ) => UseQueryResult<TData, TError>);
const useSecureQuery: UseSecureQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(queryKey, queryFn, options) => {
  const session = useSecureSession();
  const key: readonly unknown[] = typeof queryKey === 'string' ? [queryKey, session.impersonatedRole] : [...queryKey, session.impersonatedRole];
  return useQuery(key, queryFn as QueryFunction<TQueryFnData, readonly unknown[]>, options);
};

export default useSecureQuery;
