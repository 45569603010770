/** @format */

import convertFileToB64 from '@common/application/helpers/convertFileToB64';
import axios from 'axios';

export class Branch {
  id: number;
  companyId: number;
  name: string;
  addressLines: Array<string>;
  locality: string;
  region: string;
  postcode: string;
  countryId: string;
  erpId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;

  constructor(companyId: number, name: string, addressLines: Array<string>, locality: string, region: string, postcode: string, countryId: string, erpId: string) {
    this.companyId = companyId;
    this.name = name;
    this.addressLines = addressLines;
    this.locality = locality;
    this.region = region;
    this.postcode = postcode;
    this.countryId = countryId;
    this.erpId = erpId;
  }

  static list(
    query,
    sort,
    queryOpts?: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Branch; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/branches`, {
          params: {
            sequence_id: 1,
            pit,
            query,
            queryOpts: JSON.stringify(queryOpts ?? { deleted: false }),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static get(id: number): Promise<{ message: string; paginated: boolean; payload: Branch }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/branches/${id}`).then((res) => ({ ...res.data }));
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/branches/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/branches/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static update(
    updatedBranch: {
      companyId: number;
      name: string;
      addressLines: Array<string>;
      locality: string;
      region: string;
      postcode: string;
      countryId: string;
      erpId: string;
    },
    id: number,
  ): Promise<Record<string, unknown>> {
    return axios.patch(`${window.location.protocol}//${window.location.host}/api/v1/branches/${id}`, { payload: updatedBranch }).then((res) => ({ ...res.data }));
  }

  static getAddTemplate(locale): Promise<Record<string, unknown>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/templates/branches/add`, {
      // responseType: 'arraybuffer',
      params: {
        locale: locale,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static async postAddTemplate(file: File): Promise<Record<string, unknown>> {
    const fileString = await convertFileToB64(file);
    return axios.post(
      `${window.location.protocol}//${window.location.host}/api/v1/templates/branches/add`,
      { payload: fileString },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  add(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/branches`, {
        payload: this,
      })
      .then((res) => ({ ...res.data }));
  }
}
