/** @format */

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CarouselBreadcrumbs from '@molecules/CarouselBreadcrumbs';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';

interface CarouselProps {
  // A unique identifier for the carousel
  carouselId: string;
  elements: Array<{ id: string | number; element: EmotionJSX.Element }>;
  // Time in ms between auto scroll
  interval?: number;
}

const StyledCarousel = styled.div`
  //width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  //width: 100%;
  overflow: hidden;
  > svg {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    z-index: 99;
    :first-child {
      left: 10px;
    }
    &:hover {
      transition: 0.2s all ease;
      transform: translateY(-50%) scale(1.05);
    }
  }
`;

const CarouselItem = styled(motion.div)`
  position: absolute;
  inset: 0;
`;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    };
  },
};

function Carousel(props: CarouselProps) {
  const [currentIdx, setCurrentIdx] = useState(0);
  const [direction, setDirection] = useState(1);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>();

  useEffect(() => {
    if (props.elements.length - 1 > currentIdx) {
      setCurrentIdx(props.elements.length - 1);
    }
  }, [props.elements]);

  const currentItem = useMemo(() => {
    if (!props.elements.length) {
      return null;
    }
    return (
      <CarouselItem
        key={`${props.carouselId}${props.elements[currentIdx]?.id}`}
        initial="enter"
        animate="center"
        exit="exit"
        custom={direction}
        variants={variants}
        transition={{
          x: { type: 'spring', stiffness: 200, damping: 30 },
          opacity: { duration: 0.2 },
        }}
      >
        {props.elements[currentIdx]?.element}
      </CarouselItem>
    );
  }, [currentIdx, props.carouselId, props.elements]);

  const nextItem = () => {
    if (!props.elements.length) return;
    setDirection(1);
    setCurrentIdx((curr) => (curr >= props.elements.length - 1 ? 0 : curr + 1));
  };

  const previousItem = () => {
    if (!props.elements.length) return;
    setDirection(-1);
    setCurrentIdx((curr) => (curr <= 0 ? props.elements.length - 1 : curr - 1));
  };

  useEffect(() => {
    // When the float/number Infinity needs to be converted to a 32-bit integer value in JavaScript, as it does for setTimeout, it's converted to zero so cannot be used
    // As an alternative just use a very big number
    intervalRef.current = setInterval(nextItem, props.interval ?? 9999999999999);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [nextItem, props.interval]);

  return (
    <>
      <StyledCarousel>
        {props.elements.length > 1 && (
          <FontAwesomeIcon
            icon={['fas', 'chevron-left']}
            onClick={() => {
              clearInterval(intervalRef.current);
              previousItem();
            }}
          />
        )}

        <AnimatePresence initial={false} custom={direction}>
          {currentItem}
        </AnimatePresence>
        {props.elements.length > 1 && (
          <FontAwesomeIcon
            icon={['fas', 'chevron-right']}
            onClick={() => {
              clearInterval(intervalRef.current);
              nextItem();
            }}
          />
        )}
        {props.elements.length > 1 && <CarouselBreadcrumbs elements={props.elements} selectedIdx={currentIdx} onSelect={(idx) => setCurrentIdx(idx)} />}
      </StyledCarousel>
    </>
  );
}

export default Carousel;
