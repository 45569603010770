/** @format */

import Select from '@atoms/Select';
import { CurrencyContext } from '@common/application/context/CurrencyProvider';
import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from 'react-stately';

const CurrencyOption = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  > svg {
    font-size: 14px;
  }
`;

function CurrencySelect() {
  const { currencies, changeCurrency, selectedCurrency } = useContext(CurrencyContext);

  // const options = currencies.map((c) => ({ id: c.id, name: c.id }));

  return (
    <Select
      label={<FormattedMessage defaultMessage={'Select Currency'} />}
      items={currencies}
      selectedKey={selectedCurrency?.id}
      onSelectionChange={(currency) => {
        changeCurrency(currency);
      }}
    >
      {currencies.map((c) => (
        <Item key={c.id} textValue={c.id}>
          <CurrencyOption>
            {c.icon}
            {c.id}
          </CurrencyOption>
        </Item>
      ))}
    </Select>
  );
  // return <Select options={currencies as SelectOptions} onChange={(value: Option) => changeCurrency(value)} value={selectedCurrency as Option} />;
}

export default CurrencySelect;
