/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';

const StyledNavigation = styled.nav`
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.3rem;
  align-items: center;
  box-sizing: border-box;
  border-bottom: solid 1px ${cssVars.lightGrey};
  background-color: ${cssVars.offWhite};
  user-select: none;
`;

export default StyledNavigation;
