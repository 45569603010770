/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import Text from '@atoms/Text';
import { useIsAdmin } from '@common/application/hooks/useIsAdmin';
import { css } from '@emotion/react';
import { ILink } from '@organisms/MobileNav';
import { useRouter } from 'next/router';
import React from 'react';

function NavLink(props: ILink) {
  const { pathname } = useRouter();
  const { isAdmin } = useIsAdmin();

  if (props.adminOnly && !isAdmin) {
    return null;
  }

  return (
    <Text.Link
      active={props.active ?? pathname.startsWith(props.href)}
      href={props.href}
      css={css`
        color: ${cssVars.darkGrey};
      `}
    >
      {props.text}
    </Text.Link>
  );
}

export default NavLink;
